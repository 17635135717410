import { FunctionComponent, useState } from 'react';
import styled from 'styled-components';
import useTranslations from '../../../hooks/useTranslation';
import useLanguageStore from '../../../store/language';
import useUserStore from '../../../store/user';
import Button, { ButtonVariant } from '../../../components/button';
import colors from '../../../global/colors';
import Input, { InputComponentType } from '../../../components/input';
import { ChangePasswordType } from '../../../types/changePassword';
import usePasswordValidation from '../../../hooks/usePasswordValidation';
import useConfirmPasswordValidation from '../../../hooks/useConfirmPasswordValidation';
import communication from '../../../communication';
import Modal from '../../../components/modal';
import ErrorModal from '../../../components/errorModal';
import Success from '../../../components/successModal';
import Loader from '../../../components/loader';
import { MQ_BREAKPOINTS } from '../../../constants/breakpoints';

const ChangePassword: FunctionComponent = () => {
    const { currentLang, currentLangName } = useLanguageStore();
    const t = useTranslations(currentLang);

    const [openSuccessModal, setOpenSuccessModal] = useState(false);

    const [openErrorModal, setOpenErrorModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [showError, setShowError] = useState(false);

    const [loading, setLoading] = useState(false);

    const { userInfo } = useUserStore();

    const [formData, setFormData] = useState<ChangePasswordType>({
        oldPassword: '',
        newPassword: '',
        newPasswordRepeated: '',
    });
    const [clickedField, setClickedField] = useState('');

    const handleValidation = (inputName: string): void => {
        setClickedField(inputName);
    };

    const isOldPasswordValid = usePasswordValidation(formData.oldPassword);
    const isNewPasswordValid = usePasswordValidation(formData.newPassword);
    const isNewPasswordRepeatValid = useConfirmPasswordValidation(formData.newPassword, formData.newPasswordRepeated);

    const changePassword = async (): Promise<void> => {
        if (isOldPasswordValid !== '' || isNewPasswordValid !== '' || isNewPasswordRepeatValid !== '') {
            setShowError(true);
            return;
        } else {
            setShowError(false);
            try {
                setLoading(true);
                if (userInfo) {
                    const res =
                        userInfo &&
                        (await communication.changePassword({
                            email: userInfo?.email,
                            old_password: formData.oldPassword,
                            new_password: formData.newPassword,
                            confirm: formData.newPasswordRepeated,
                        }));
                    if (res?.status === 200) {
                        setLoading(false);
                        setOpenSuccessModal(true);
                    }
                } else {
                    const userData = await communication.getProfile();
                    const res = await communication.changePassword({
                        email: userData.data.data.email,
                        old_password: formData.oldPassword,
                        new_password: formData.newPassword,
                        confirm: formData.newPasswordRepeated,
                    });
                    if (res?.status === 200) {
                        setLoading(false);
                        setOpenSuccessModal(true);
                    }
                }
            } catch (err: any) {
                setLoading(false);
                setErrorMessage(
                    currentLangName === 'English'
                        ? t('pages.agency.changePassword.error').text
                        : err.response.data.message,
                );
                setOpenErrorModal(true);
            }
        }
    };

    return (
        <>
            {loading && <Loader />}
            {openErrorModal && (
                <Modal modalVisible={openErrorModal} closeModal={() => setOpenErrorModal(false)}>
                    <ErrorModal t={t} setOpenModal={setOpenErrorModal} errorMessage={errorMessage} />
                </Modal>
            )}
            {openSuccessModal && (
                <Modal modalVisible={true} closeModal={() => setOpenSuccessModal(false)}>
                    <Success
                        close={() => setOpenSuccessModal(false)}
                        message={t('pages.agency.changePassword.success').text}
                    />
                </Modal>
            )}
            <Content>
                <Input
                    type={InputComponentType.Password}
                    label={t('pages.agency.changePassword.oldPassword').text}
                    value={formData?.oldPassword}
                    onChange={(value: string) => {
                        setFormData({ ...formData, oldPassword: value });
                    }}
                    validation={showError && isOldPasswordValid ? isOldPasswordValid : ''}
                    blurHandler={() => handleValidation('oldPassword')}
                />
                <Input
                    type={InputComponentType.Password}
                    label={t('pages.agency.changePassword.newPassword').text}
                    value={formData?.newPassword}
                    onChange={(value: string) => {
                        setFormData({ ...formData, newPassword: value });
                    }}
                    validation={showError && isNewPasswordValid ? isNewPasswordValid : ''}
                    blurHandler={() => handleValidation('newPassword')}
                />
                <Input
                    type={InputComponentType.Password}
                    label={t('pages.agency.changePassword.newPasswordAgain').text}
                    value={formData?.newPasswordRepeated}
                    onChange={(value: string) => {
                        setFormData({ ...formData, newPasswordRepeated: value });
                    }}
                    validation={showError && isNewPasswordRepeatValid ? isNewPasswordRepeatValid : ''}
                    blurHandler={() => handleValidation('newPasswordRepeated')}
                />

                <div className="button-container">
                    <Button variant={ButtonVariant.solid} color={colors.purple} size={200} onClick={changePassword}>
                        {t('pages.agency.changePassword.change').text}
                    </Button>
                </div>
            </Content>
        </>
    );
};
export default ChangePassword;

const Content = styled.div`
    width: 56%;
    display: inline-block;
    vertical-align: top;

    .button-container {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 50px;

        @media screen and (max-width: ${MQ_BREAKPOINTS.tablet}) {
            padding-bottom: 80px;
            margin-top: 20px;
        }
    }
    .input-container {
        width: 100%;
        label {
            color: var(--gray);
        }
    }

    @media screen and (max-width: ${MQ_BREAKPOINTS.tablet}) {
        width: 100%;
    }

    .input-container {
        width: -webkit-fill-available;
        @media screen and (max-width: ${MQ_BREAKPOINTS.tablet}) {
            margin-bottom: 0px;
            label {
                margin-bottom: 10px;
            }
        }
    }

    input {
        @media screen and (max-width: ${MQ_BREAKPOINTS.tablet}) {
            border: 1px solid var(--border-color);
            background: var(--white);
            margin-bottom: 20px;
        }
    }
`;
