import { Dispatch, FunctionComponent, SetStateAction, useState } from 'react';
import moment from 'moment';
import styled from 'styled-components';
import colors from '../../../../global/colors';
import useGeneralStore from '../../../../store/general';
import Button, { ButtonVariant } from '../../../../components/button';
import Input, { InputComponentType } from '../../../../components/input';
import useDateOfInvoiceCancelation from '../../../../hooks/useDateOfInvoiceCancelation';
import communication from '../../../../communication';
import { ObjectKeys } from '../../../../types/objectKeys';
import Loader from '../../../../components/loader';
import useCharLengthValidation from '../../../../hooks/useCharLengthValidation';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
type Props = {
    t: Function;
    setOpenCancelModal: Dispatch<SetStateAction<boolean>>;
    clickedInvoice: ObjectKeys;
    onCancelInvoice: (confirmed: boolean) => void;
};

const CancelModal: FunctionComponent<Props> = ({ t, setOpenCancelModal, clickedInvoice, onCancelInvoice }) => {
    const [invoiceCancelDate, setInvoiceCancelDate] = useState(null);
    const { setGlobalModal, closeGlobalModal } = useGeneralStore();
    const [showValidationMessage, setShowValidationMessage] = useState(false);
    const [loaderVisible, setLoaderVisible] = useState<boolean>(false);
    const [einvoiceComment, setEinvoiceComment] = useState('');
    const isInvoiceCancelDateValid = useDateOfInvoiceCancelation(invoiceCancelDate);
    const isCommentValid = useCharLengthValidation(einvoiceComment, 5);

    const handleCancelInvoice = (invoiceId: string): void => {
        setShowValidationMessage(true);
        if (
            invoiceCancelDate !== null &&
            isInvoiceCancelDateValid === '' &&
            (!clickedInvoice.is_einvoice || isCommentValid === '')
        ) {
            setOpenCancelModal(false);
            setLoaderVisible(true);
            communication
                .cancelInvoice(invoiceId, {
                    cancel_date: moment(invoiceCancelDate).format('YYYY-MM-DD'),
                    e_invoice_cancel_comment: einvoiceComment,
                })
                .then((res: ObjectKeys) => {
                    if (res) {
                        setLoaderVisible(false);
                    }
                })
                .then(() => {
                    onCancelInvoice(true);
                    toast.success(
                        clickedInvoice.is_einvoice && !clickedInvoice.is_prepaid
                            ? t('pages.eInvoices.cancelModal.success').text
                            : clickedInvoice.is_einvoice && clickedInvoice.is_prepaid
                            ? t('pages.eInvoices.cancelModal.success_eAdvance').text
                            : clickedInvoice.is_prepaid
                            ? t('pages.advanceInvoices.cancelModal.success').text
                            : t('pages.invoices.cancelModal.success').text,
                    );
                })
                .catch((error: ObjectKeys) => {
                    setLoaderVisible(false);
                    toast.success(
                        clickedInvoice.is_einvoice
                            ? t('pages.eInvoices.cancelModal.failed').text
                            : clickedInvoice.is_prepaid
                            ? t('pages.advanceInvoices.cancelModal.failed').text
                            : t('pages.invoices.cancelModal.failed').text,
                    );
                    if (error) {
                        setGlobalModal(
                            <ErrorModalWrapper>
                                <ModalText>
                                    <h4>{t('modal.error.title').text}</h4>
                                    <p>{error.response.data.message}</p>
                                </ModalText>
                                <Button
                                    variant={ButtonVariant.solid}
                                    color={colors.purple}
                                    size={'100%'}
                                    onClick={() => {
                                        closeGlobalModal();
                                    }}
                                >
                                    {t('buttons.ok').text}
                                </Button>
                            </ErrorModalWrapper>,
                        );
                    }
                });
        }
    };

    return (
        <>
            {loaderVisible && <Loader />}
            <ToastContainer />
            <CancelModalWrapper>
                <ModalContent>
                    {clickedInvoice.is_einvoice && !clickedInvoice.is_prepaid ? (
                        <h4>{t('pages.eInvoices.cancelModal.title').text}</h4>
                    ) : clickedInvoice.is_prepaid && clickedInvoice.is_einvoice ? (
                        <h4>{t('pages.eInvoices.cancelModal.title_eAdvance').text}</h4>
                    ) : clickedInvoice.is_prepaid ? (
                        <h4>{t('pages.advanceInvoices.cancelModal.title').text}</h4>
                    ) : (
                        <h4>{t('pages.invoices.cancelModal.title').text}</h4>
                    )}
                    {clickedInvoice.is_einvoice && !clickedInvoice.is_prepaid ? (
                        <p>{t('pages.eInvoices.cancelModal.description').text}</p>
                    ) : clickedInvoice.is_prepaid && clickedInvoice.is_einvoice ? (
                        <p>{t('pages.eInvoices.cancelModal.description_eAdvance').text}</p>
                    ) : clickedInvoice.is_prepaid ? (
                        <p>{t('pages.advanceInvoices.cancelModal.description').text}</p>
                    ) : (
                        <p>{t('pages.invoices.cancelModal.description').text}</p>
                    )}
                    <DateWrapper>
                        <Input
                            type={InputComponentType.Date}
                            label={
                                clickedInvoice.is_einvoice && !clickedInvoice.is_prepaid
                                    ? t('pages.eInvoices.cancelModal.inputLabel').text
                                    : clickedInvoice.is_einvoice && clickedInvoice.is_prepaid
                                    ? t('pages.eInvoices.cancelModal.inputLabel_eAdvance').text
                                    : clickedInvoice.is_prepaid
                                    ? t('pages.advanceInvoices.cancelModal.inputLabel').text
                                    : t('pages.invoices.cancelModal.inputLabel').text
                            }
                            date={invoiceCancelDate}
                            validation={showValidationMessage ? isInvoiceCancelDateValid : ''}
                            onChange={(value: any) => {
                                setInvoiceCancelDate(value);
                            }}
                            blurHandler={() => setShowValidationMessage(true)}
                            maxDate={new Date()}
                        />
                    </DateWrapper>
                    {clickedInvoice.is_einvoice ? (
                        <Input
                            type={InputComponentType.Text}
                            label={
                                clickedInvoice.is_prepaid
                                    ? t('pages.eInvoices.cancelModal.inputComment_eAdvance').text
                                    : t('pages.eInvoices.cancelModal.inputComment').text
                            }
                            onChange={(value: string) => {
                                setEinvoiceComment(value);
                            }}
                            validation={showValidationMessage ? isCommentValid : ''}
                            blurHandler={() => setShowValidationMessage(true)}
                        />
                    ) : null}
                </ModalContent>

                <ButtonContainer>
                    <Button
                        variant={ButtonVariant.solid}
                        color={colors.purple}
                        onClick={() => {
                            handleCancelInvoice(clickedInvoice.id);
                        }}
                        size={'100%'}
                    >
                        {t('input.yes').text}
                    </Button>
                    <Button
                        variant={ButtonVariant.solid}
                        color={colors.purple}
                        onClick={() => setOpenCancelModal(false)}
                        size={'100%'}
                    >
                        {t('input.no').text}
                    </Button>
                </ButtonContainer>
            </CancelModalWrapper>
        </>
    );
};

const CancelModalWrapper = styled.div`
    text-align: center;
    button {
        border-radius: 0 0 5px 5px;
    }
`;
const ButtonContainer = styled.div`
    display: flex;
    width: 50%;
    button {
        border: none;
    }
    button:first-child {
        border-radius: 0px 0px 0px 5px !important;
    }
    button:last-child {
        background-color: var(--white);
        color: var(--black);
        border-radius: 0px 0px 5px 0px !important;
        border-top: 1px solid var(--gray);
    }
`;
const ModalContent = styled.div`
    padding: 35px 50px;
    text-align: center;

    h4 {
        margin-bottom: 20px;
    }
    p {
        color: var(--gray);
        font-size: 16px;
        line-height: 1.3;
    }
`;
const DateWrapper = styled.div`
    margin-bottom: 20px;
    margin-top: 20px;
    .input {
        > div {
            margin-bottom: 0;
        }
    }
`;
const ErrorModalWrapper = styled.div`
    text-align: center;
    button {
        border-radius: 0 0 5px 5px;
    }
`;
const ModalText = styled.div`
    padding: 35px 50px 30px;
    h4 {
        margin-bottom: 30px;
    }
    p {
        line-height: 30px;
    }
`;
export default CancelModal;
