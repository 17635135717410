export const breakpoints = {
    mobileS: 320,
    mobileM: 375,
    mobileL: 425,
    tablet: 768,
    laptop: 1024,
    laptopL: 1440,
};

export const MQ_BREAKPOINTS = {
    mobileS: `${breakpoints.mobileS}px`,
    mobileM: `${breakpoints.mobileM}px`,
    mobileL: `${breakpoints.mobileL}px`,
    tablet: `${breakpoints.tablet}px`,
    laptop: `${breakpoints.laptop}px`,
    laptopL: `${breakpoints.laptopL}px`,
};
