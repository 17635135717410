import { FunctionComponent } from 'react';
import styled from 'styled-components';
import useLanguageStore from '../../../../store/language';
import useChangeModalStore from '../../../../store/changeModal';
import useTranslations from '../../../../hooks/useTranslation';
import Button, { ButtonVariant } from '../../../../components/button';
import communication from '../../../../communication';

interface Props {
    close: Function;
    message: string;
    active?: boolean;
    className?: string;
    children?: string | any;
    year: string;
    modalMessage: Function;
}

const DownloadModal: FunctionComponent<Props> = ({ close, message, year, modalMessage }) => {
    const { currentLang } = useLanguageStore();
    const t = useTranslations(currentLang);

    const token = localStorage.getItem('access_token');

    const handleSend = async (y: string): Promise<void> => {
        const res = await communication.sendKpoToEmail({ token }, y);
        if (res.status === 200) modalMessage(t('pages.kpo.downloadModal.sent').text);
    };

    const handleDownload = async (y: string): Promise<void> => {
        if (!token) return;
        else {
            const response = await communication.downloadKpo(y, token);
            // Creating new object of PDF file
            const file = new Blob([response?.data], { type: 'application/pdf' });
            const fileURL = window.URL.createObjectURL(file);
            // Setting various property values
            const alink = document.createElement('a');
            alink.href = fileURL;
            alink.download = `KPO_${y}.pdf`;
            alink.click();
        }
    };

    return (
        <>
            <TextContainer>
                <h3>{message}</h3>
            </TextContainer>
            <ButtonContainer>
                <Button
                    textTransformNone
                    variant={ButtonVariant.solid}
                    color={'var(--purple)'}
                    onClick={() => {
                        useChangeModalStore.setState({ changeModalVisible: false });
                        handleDownload(year);
                        close();
                    }}
                    size={'50%'}
                >
                    {t('pages.kpo.downloadModal.download').text}
                </Button>

                <Button
                    textTransformNone
                    variant={ButtonVariant.outlined}
                    color={'var(--white)'}
                    onClick={() => {
                        handleSend(year);
                        useChangeModalStore.setState({ changeModalVisible: false });
                        close();
                    }}
                    size={'50%'}
                >
                    {t('pages.kpo.downloadModal.viaEmail').text}
                </Button>
            </ButtonContainer>
        </>
    );
};

export default DownloadModal;

const TextContainer = styled.div`
    padding: 35px 50px;
    text-align: center;
    min-height: 130px;
    display: flex;
    justify-content: center;
    h3 {
        padding-top: 0px;
        margin-top: 0px;
        color: var(--black);
        font-size: 20px;
    }
`;

const ButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
    button {
        border-radius: 0px !important;
        :nth-child(2) {
            color: var(--black);
            border: 1px solid var(--border-color);
        }
    }
`;
