import React from 'react';
import styled, { keyframes } from 'styled-components';
import { MQ_BREAKPOINTS } from '../../../../../constants/breakpoints';

const InvoiceCardSkeleton: React.FC = () => {
    return (
        <StyledInvoiceCard>
            <CardContent>
                <InfoWrapper className="info-wrapper">
                    <SkeletonBox className="name-col">
                        <div className="label skeleton"></div>
                        <div className="skeleton skeleton-text"></div>
                    </SkeletonBox>
                    <SkeletonBox className="number-col">
                        <div className="label skeleton"></div>
                        <div className="skeleton skeleton-text"></div>
                    </SkeletonBox>
                    <SkeletonBox className="date-col">
                        <div className="label skeleton"></div>
                        <div className="skeleton skeleton-text"></div>
                    </SkeletonBox>
                    <SkeletonBox className="amount-col">
                        <div className="label skeleton"></div>
                        <div className="skeleton skeleton-text"></div>
                    </SkeletonBox>
                    <SkeletonBox className="status-col">
                        <div className="label skeleton"></div>
                        <div className="skeleton skeleton-status"></div>
                    </SkeletonBox>
                </InfoWrapper>
                <ActionWrapper>
                    <SkeletonBox className="action">
                        <div className="skeleton skeleton-icon"></div>
                    </SkeletonBox>
                </ActionWrapper>
            </CardContent>
        </StyledInvoiceCard>
    );
};

const loadingAnimation = keyframes`
    0% {
        background-position: -200px 0;
    }
    100% {
        background-position: calc(200px + 100%) 0;
    }
`;

const StyledInvoiceCard = styled.div`
    margin-bottom: 10px;
    padding: 16px 20px;
    border-radius: 5px;
    width: 100%;
    background: var(--white);
    border: 1px solid var(--purple);
    transition: all 0.5s ease-in-out;
    &:hover {
        box-shadow: 0 0 5px 5px #eee;
        .info-wrapper {
            opacity: 0.7;
        }
    }
    @media screen and (max-width: ${MQ_BREAKPOINTS.tablet}) {
        align-items: start;
    }
    &.card-display-grid {
        display: flex;
        flex-direction: column;
        .card-content {
            grid-template-columns: 1fr;
            width: 100%;
            .info-wrapper {
                grid-template-columns: 2fr 2fr;
                > div {
                    &:nth-of-type(1) {
                        order: 3;
                        grid-column: 1 / 4;
                    }
                    &:nth-of-type(2) {
                        order: 2;
                        margin-bottom: 20px;
                        display: flex;
                        flex-direction: column;
                        align-items: flex-end;
                    }
                    &:nth-of-type(3) {
                        order: 4;
                        grid-column: 1 / 4;
                    }
                    &:nth-of-type(4) {
                        order: 4;
                    }
                    &.status-col {
                        order: 1;
                        margin-bottom: 20px;
                    }
                }
            }
        }
    }
`;

const CardContent = styled.div`
    display: grid;
    align-items: center;
    grid-template-columns: 3fr 1fr;
    @media screen and (max-width: ${MQ_BREAKPOINTS.laptopL}) {
        grid-template-columns: 8fr 1fr;
    }

    div {
        color: var(--black);
    }
`;

const InfoWrapper = styled.div`
    grid-template-columns: 2fr 1fr 1fr 1fr 1fr 1fr;
    display: grid;
    align-items: center;
    gap: 16px;
    @media screen and (max-width: ${MQ_BREAKPOINTS.tablet}) {
        grid-template-columns: 2fr 2fr;
    }

    .label {
        font-size: 12px;
        color: var(--gray);
        line-height: 25px;
        margin-bottom: 8px;
    }

    .name-col,
    .number-col,
    .date-col,
    .amount-col,
    .status-col {
        display: flex;
        flex-direction: column;
        gap: 8px;
    }
`;

const SkeletonBox = styled.div`
    .label.skeleton,
    .skeleton {
        background: linear-gradient(90deg, #e6dfff 25%, #cebcff 50%, #b6a0ff 75%);
        background-size: 200% 100%;
        animation: ${loadingAnimation} 1.5s infinite;
        border-radius: 4px;
    }

    .skeleton-text {
        height: 18px;
        margin: 8px 0;
        width: 80%;
    }

    .skeleton-status {
        height: 12px;
        width: 60%;
    }

    .skeleton-icon {
        height: 25px;
        width: 25px;
        border-radius: 50%;
    }

    .name-col .skeleton-text,
    .number-col .skeleton-text,
    .date-col .skeleton-text,
    .amount-col .skeleton-text {
        width: 100%;
    }
`;

const ActionWrapper = styled.div`
    display: flex;
    justify-content: flex-end;

    div {
        &.action {
            justify-content: center;
            margin-top: 20px;
        }
    }

    .skeleton-icon {
        margin-left: auto;
    }
`;

export default InvoiceCardSkeleton;
