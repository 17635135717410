import { FunctionComponent, useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faDownload,
    faClone,
    faTrashCan,
    faEnvelope,
    faCheckCircle,
    faE,
    faEllipsisVertical,
    faMinusCircle,
    faRefresh,
    faBell,
    faEraser,
} from '@fortawesome/free-solid-svg-icons';
import colors from '../../global/colors';
import { formatNumber } from '../../functions/format';
import useTranslations from '../../hooks/useTranslation';
import useLanguageStore from '../../store/language';
import { ObjectKeys } from '../../types/objectKeys';
import { Link } from 'react-router-dom';
import useDropdown from '../invoiceCard/useDropdown';
import Dropdown from '../dropdown';
import getStatus from '../invoiceCard/getStatus';
import { Icon } from '../invoiceCard/invoiceActionsWithoutDropdown';
import useProfileStore from '../../store/profile';
import PreviewInvoiceModalPlain from '../../pages/pausal/common/previewInvoiceModalPlain';
import PreviewInvoiceModalArtistic from '../../pages/pausal/common/previewInvoiceModalArtistic';
import PreviewInvoiceModalBusiness from '../../pages/pausal/common/previewInvoiceModalBusiness';
import PreviewInvoiceModalInvoicePro from '../../pages/pausal/common/previewInvoiceModalInvoicePro';
import PreviewInvoiceModalPlain2 from '../../pages/pausal/common/previewInvoiceModalPlain2';
import useUserStore from '../../store/user';
import useAgencyStore from '../../store/agency';
import Modal from '../modal';
import { generateQrCode } from '../../communication/qr';
import { MQ_BREAKPOINTS } from '../../constants/breakpoints';
import useResponsive from '../../hooks/responsive/useResponsive';

export enum EInvoiceCardComponentType {
    Invoice = 'invoice',
    EInvoice = 'eInvoice',
    Proforma = 'proforma',
    AdvanceInvoices = 'advanceInvoices',
}
interface Props {
    type: string;
    className?: string;
    handleChange?: Function;
    handleDownload?: Function;
    handleCopy?: Function;
    handleCancel?: Function;
    handleDelete?: Function;
    handleSend?: Function;
    handleReminder?: Function;
    handlePayment?: Function;
    handleEInvoice?: Function;
    handleClick: Function;
    handleRefresh?: Function;
    cardDisplay: string;
    cardData: ObjectKeys;
    eInvoice?: boolean;
    handleRemovePayments?: Function;
    dropdownDirection?: 'up' | 'down';
}
const EInvoiceCard: FunctionComponent<Props> = ({
    type,
    className,
    handleChange,
    handleCancel,
    handleCopy,
    handleDelete,
    handleDownload,
    handlePayment,
    handleSend,
    handleReminder,
    handleClick,
    handleRefresh,
    cardDisplay,
    cardData,
    eInvoice,
    handleRemovePayments,
    dropdownDirection = 'down',
}) => {
    const { currentLang } = useLanguageStore();
    const t = useTranslations(currentLang);
    const ref = useRef<HTMLDivElement>(null);
    const { profile } = useProfileStore();
    const { isLaptopAndDown } = useResponsive();

    const isAccountActive = profile?.active === 1 ? true : false;

    const isAdvance = cardData?.is_prepaid ? true : false;
    const eAdvanceWithPrepaids = cardData.is_einvoice && cardData?.prepaid_invoices?.length > 0 ? false : true;
    const isCanceledPaidInvoice = cardData.status === 'paid' && cardData.cancelled ? false : true;

    const [previewModal, setPreviewModal] = useState(false);
    const { agency } = useAgencyStore();
    const { userInfo } = useUserStore();
    const preferredTemplate = userInfo?.preferred_template?.data.display_name;
    const [qrCode, setQrCode] = useState('');

    const [dropdownVisible, setDropdownVisible] = useState(false);

    //QR code
    const handleGenerateQRCode = (
        agencyData: ObjectKeys,
        clientData: ObjectKeys,
        amount: string,
        invoiceNumber: string,
        bankAccount: string,
        currency: string,
        convert: boolean,
    ): void => {
        try {
            generateQrCode(
                agencyData,
                clientData,
                amount,
                invoiceNumber,
                bankAccount,
                currency,
                convert,
                `00${invoiceNumber?.replace('/', '-')}`,
                '221',
            )
                .then((response: ObjectKeys | any) => {
                    if (response.i) setQrCode(response.i);
                })
                .catch((error: any) => {
                    setQrCode('');
                    if (error) {
                        console.error(error);
                    }
                });
        } catch (e: any) {
            setQrCode('');
        }
    };

    const paidDropdown = useDropdown(
        cardData,
        type,
        'paid',
        undefined,
        undefined,
        handleCopy,
        undefined,
        handleSend,
        undefined,
        undefined,
        handleDownload,
        undefined,
        undefined,
        undefined,
        handleRefresh,
        eInvoice,
        handleRemovePayments,
        isAdvance,
    );
    const sentDropdown = useDropdown(
        cardData,
        type,
        'sent',
        undefined,
        handleCancel,
        handleCopy,
        undefined,
        handleSend,
        handleReminder,
        handlePayment,
        handleDownload,
        undefined,
        undefined,
        undefined,
        handleRefresh,
        eInvoice,
        undefined,
        isAdvance,
    );
    const draftDropdown = useDropdown(
        cardData,
        type,
        'draft',
        undefined,
        undefined,
        handleCopy,
        undefined,
        handleSend,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        handleRefresh,
        eInvoice,
        undefined,
        isAdvance,
    );
    const partiallyDropdown = useDropdown(
        cardData,
        type,
        'partially',
        undefined,
        undefined,
        handleCopy,
        undefined,
        handleSend,
        undefined,
        handlePayment,
        handleDownload,
        undefined,
        undefined,
        undefined,
        handleRefresh,
        eInvoice,
        handleRemovePayments,
        isAdvance,
    );
    const cancelledDropdown = useDropdown(
        cardData,
        type,
        'cancelled',
        undefined,
        handleCancel,
        handleCopy,
        undefined,
        handleSend,
        undefined,
        handlePayment,
        handleDownload,
        undefined,
        undefined,
        undefined,
        handleRefresh,
        eInvoice,
        undefined,
        isAdvance,
    );

    // On outside click close the menu
    const handleClickOutside = (event: MouseEvent): void => {
        if (!ref?.current?.contains(event.target as HTMLDivElement)) {
            setDropdownVisible(false);
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, []);
    return (
        <StyledInvoiceCard key={cardData.id} className={`card ${className ?? ''} card-display-${cardDisplay}`.trim()}>
            {previewModal && (
                <Modal
                    width="700px"
                    className="preview-modal"
                    modalVisible={true}
                    hasCloseButton={true}
                    closeModal={() => {
                        setPreviewModal(false);
                    }}
                >
                    {preferredTemplate === 'Plain' && (
                        <PreviewInvoiceModalPlain
                            agency={agency}
                            invoice={cardData}
                            title={cardData.is_prepaid ? 'advance' : cardData.invoice_number ? 'invoice' : 'proforma'}
                            type={cardData.type === 'national' ? 'domestic' : 'foreign'}
                            note={cardData.comment}
                            qrCode={qrCode}
                        />
                    )}
                    {preferredTemplate === 'Artistic' && (
                        <PreviewInvoiceModalArtistic
                            agency={agency}
                            invoice={cardData}
                            title={cardData.is_prepaid ? 'advance' : cardData.invoice_number ? 'invoice' : 'proforma'}
                            type={cardData.type === 'national' ? 'domestic' : 'foreign'}
                            note={cardData.comment}
                            qrCode={qrCode}
                        />
                    )}
                    {preferredTemplate === 'Business' && (
                        <PreviewInvoiceModalBusiness
                            agency={agency}
                            invoice={cardData}
                            title={cardData.is_prepaid ? 'advance' : cardData.invoice_number ? 'invoice' : 'proforma'}
                            type={cardData.type === 'national' ? 'domestic' : 'foreign'}
                            note={cardData.comment}
                            qrCode={qrCode}
                        />
                    )}
                    {preferredTemplate === 'InvoicePro' && (
                        <PreviewInvoiceModalInvoicePro
                            agency={agency}
                            invoice={cardData}
                            title={cardData.is_prepaid ? 'advance' : cardData.invoice_number ? 'invoice' : 'proforma'}
                            type={cardData.type === 'national' ? 'domestic' : 'foreign'}
                            note={cardData.comment}
                            qrCode={qrCode}
                        />
                    )}
                    {preferredTemplate === 'Plain 2' && (
                        <PreviewInvoiceModalPlain2
                            agency={agency}
                            invoice={cardData}
                            title={cardData.is_prepaid ? 'advance' : cardData.invoice_number ? 'invoice' : 'proforma'}
                            type={cardData.type === 'national' ? 'domestic' : 'foreign'}
                            note={cardData.comment}
                            qrCode={qrCode}
                        />
                    )}
                </Modal>
            )}
            <CardContent className="card-content cursor-pointer">
                <InfoWrapper
                    onClick={() => {
                        if (!isAccountActive) {
                            handleDownload && handleDownload(cardData.id);
                        } else {
                            setPreviewModal(true);
                            if (cardData.type !== 'foreign' && cardData.is_prepaid !== 1) {
                                handleGenerateQRCode(
                                    agency.full_name,
                                    cardData?.client?.data?.company_name,
                                    cardData?.total_for_payment_in_rsd,
                                    cardData?.invoice_number ? cardData?.invoice_number : cardData?.quotation_number,
                                    cardData?.bank_account,
                                    cardData?.currency,
                                    true,
                                );
                            }
                        }
                    }}
                    className="info-wrapper"
                >
                    <div className="name-col">
                        <div className="label">{t('pages.invoiceCard.infoWrapper.name').text}</div>
                        <div className="name">{cardData?.client?.data?.company_name}</div>
                    </div>
                    <div className="number-col">
                        <div className="label">
                            {t('pages.invoiceCard.infoWrapper.number').text +
                                ' ' +
                                (type === 'invoice' ? t('pages.invoiceCard.infoWrapper.invoice').text : '')}
                        </div>
                        <div className="number">
                            {cardData?.invoice_number
                                ? cardData?.invoice_number
                                : getStatus(cardData?.status?.toLowerCase(), t)}
                        </div>
                    </div>
                    <div className="date-col">
                        <div className="label">{t('pages.invoiceCard.infoWrapper.date').text}</div>
                        <div className="date">{moment(cardData?.invoice_date)?.format('DD.MM.YYYY')}</div>
                    </div>
                    <div className="amount-col">
                        <div className="label">{t('pages.invoiceCard.infoWrapper.amount').text}</div>
                        <div className="amount">
                            {formatNumber(cardData?.value, 2, 3, '.', ',') + ' ' + cardData?.currency}
                        </div>
                    </div>
                    <div className="status-col">
                        <div className="label">{t('pages.invoiceCard.infoWrapper.status').text}</div>
                        <div className={`status ${cardData?.status}`}>
                            <span>{getStatus(cardData?.status?.toLowerCase(), t)}</span>
                        </div>
                    </div>
                    {eInvoice &&
                        (() => {
                            const statusRaw = cardData?.eInvoice?.data?.status;
                            const status = statusRaw ? statusRaw.toLowerCase() : null;
                            return (
                                <div className="status-col">
                                    <div className="label">
                                        {isAdvance
                                            ? t('pages.eInvoices.status.title_eAdvance').text
                                            : t('pages.eInvoices.status.title').text}
                                    </div>
                                    <div className={`status ${status}`}>
                                        <span>{getStatus(status, t, eInvoice)}</span>
                                    </div>
                                </div>
                            );
                        })()}
                </InfoWrapper>
                {isAccountActive ? (
                    <ActionWrapper>
                        <div className="label"></div>
                        <div className="action">
                            {isLaptopAndDown ? (
                                <Action>
                                    <FontAwesomeIcon
                                        icon={faEllipsisVertical}
                                        style={{ color: colors.gray }}
                                        className="cursor-pointer dots"
                                        onClick={() => {
                                            handleClick(cardData?.id);
                                            setDropdownVisible(!dropdownVisible);
                                        }}
                                    />
                                    {dropdownVisible && (
                                        <DropdownContainer
                                            ref={ref}
                                            onClick={(e: MouseEvent | any) => {
                                                handleClickOutside(e);
                                                setDropdownVisible(false);
                                            }}
                                            className={dropdownDirection}
                                        >
                                            <Dropdown
                                                arrowRight="4px"
                                                caretDown={dropdownDirection === 'up'}
                                                itemList={
                                                    cardData?.status === 'paid'
                                                        ? paidDropdown
                                                        : cardData?.status === 'sent'
                                                        ? sentDropdown
                                                        : cardData?.status === 'open'
                                                        ? draftDropdown
                                                        : cardData?.status === 'partially'
                                                        ? partiallyDropdown
                                                        : cardData?.status === 'cancelled' ||
                                                          cardData?.status === 'closed'
                                                        ? cancelledDropdown
                                                        : []
                                                }
                                            />
                                        </DropdownContainer>
                                    )}
                                </Action>
                            ) : (
                                <ActionWithoutDropdown>
                                    {cardData?.status === 'paid' ? (
                                        <>
                                            {eInvoice && (
                                                <Icon>
                                                    <FontAwesomeIcon
                                                        icon={faRefresh}
                                                        style={{ color: colors.purple }}
                                                        onClick={(e) => handleRefresh && handleRefresh(e)}
                                                    />
                                                    <div className="label">
                                                        {t('pages.invoiceCard.dropdownItems.refresh').text}
                                                    </div>
                                                </Icon>
                                            )}
                                            <Icon>
                                                <FontAwesomeIcon
                                                    icon={faDownload}
                                                    style={{ color: colors.blue }}
                                                    onClick={(e) => handleDownload && handleDownload(e)}
                                                />
                                                <div className="label">
                                                    {t('pages.invoiceCard.dropdownItems.download').text}
                                                </div>
                                            </Icon>
                                            <Link to={`/e-invoices/edit/${cardData.id}`}>
                                                <Icon>
                                                    <FontAwesomeIcon
                                                        icon={faClone}
                                                        style={{ color: colors.purple }}
                                                        onClick={(e) => handleCopy && handleCopy(e)}
                                                    />
                                                    <div className="label">
                                                        {t('pages.invoiceCard.dropdownItems.copy').text}
                                                    </div>
                                                </Icon>
                                            </Link>
                                            {!eInvoice && (
                                                <Icon>
                                                    <FontAwesomeIcon
                                                        icon={faTrashCan}
                                                        style={{ color: colors.danger }}
                                                        onClick={(e) => handleDelete && handleDelete(e)}
                                                    />
                                                    <div className="label">
                                                        {t('pages.invoiceCard.dropdownItems.delete').text}
                                                    </div>
                                                </Icon>
                                            )}
                                            <Icon>
                                                <FontAwesomeIcon
                                                    icon={faEnvelope}
                                                    style={{ color: colors.blue }}
                                                    onClick={(e) => handleSend && handleSend(e)}
                                                />
                                                <div className="label">
                                                    {t('pages.invoiceCard.dropdownItems.send').text}
                                                </div>
                                            </Icon>
                                            <FontAwesomeIcon icon={faE} style={{ color: colors.gray }} />
                                            {eAdvanceWithPrepaids && isCanceledPaidInvoice ? (
                                                <Icon>
                                                    <FontAwesomeIcon
                                                        icon={faEraser}
                                                        style={{ color: colors.danger }}
                                                        onClick={(e) => handleRemovePayments && handleRemovePayments(e)}
                                                    />
                                                    <div className="label">
                                                        {t('pages.invoiceCard.dropdownItems.removePayment').text}
                                                    </div>
                                                </Icon>
                                            ) : null}
                                        </>
                                    ) : cardData?.status === 'open' ? (
                                        <>
                                            <Link to={`/e-invoices/edit/${cardData.id}`}>
                                                <Icon>
                                                    <FontAwesomeIcon
                                                        icon={faClone}
                                                        style={{ color: colors.purple }}
                                                        onClick={(e) => handleCopy && handleCopy(e)}
                                                    />
                                                    <div className="label">
                                                        {t('pages.invoiceCard.dropdownItems.copy').text}
                                                    </div>
                                                </Icon>
                                            </Link>
                                            {!eInvoice && (
                                                <Icon>
                                                    <FontAwesomeIcon
                                                        icon={faTrashCan}
                                                        style={{ color: colors.danger }}
                                                        onClick={(e) => handleDelete && handleDelete(e)}
                                                    />
                                                    <div className="label">
                                                        {t('pages.invoiceCard.dropdownItems.delete').text}
                                                    </div>
                                                </Icon>
                                            )}
                                            <Icon>
                                                <FontAwesomeIcon
                                                    icon={faEnvelope}
                                                    style={{ color: colors.blue }}
                                                    onClick={(e) => handleSend && handleSend(e)}
                                                />
                                                <div className="label">
                                                    {t('pages.invoiceCard.dropdownItems.send').text}
                                                </div>
                                            </Icon>
                                            {eInvoice && <FontAwesomeIcon icon={faE} style={{ color: colors.gray }} />}
                                        </>
                                    ) : cardData?.status === 'sent' ? (
                                        <>
                                            {eInvoice &&
                                                (cardData.eInvoice.data.status === 'Sent' ||
                                                    cardData.eInvoice.data.status === 'Sending' ||
                                                    cardData.eInvoice.data.status === 'Approved' ||
                                                    cardData.eInvoice.data.status === 'Rejected') && (
                                                    <Icon>
                                                        <FontAwesomeIcon
                                                            icon={faRefresh}
                                                            style={{ color: colors.purple }}
                                                            onClick={(e) => handleRefresh && handleRefresh(e)}
                                                        />
                                                        <div className="label">
                                                            {t('pages.invoiceCard.dropdownItems.refresh').text}
                                                        </div>
                                                    </Icon>
                                                )}
                                            <Icon>
                                                <FontAwesomeIcon
                                                    icon={faDownload}
                                                    style={{ color: colors.blue }}
                                                    onClick={(e) => handleDownload && handleDownload(e)}
                                                />
                                                <div className="label">
                                                    {t('pages.invoiceCard.dropdownItems.download').text}
                                                </div>
                                            </Icon>
                                            <Link to={`/e-invoices/edit/${cardData.id}`}>
                                                <Icon>
                                                    <FontAwesomeIcon
                                                        icon={faClone}
                                                        style={{ color: colors.purple }}
                                                        onClick={(e) => handleCopy && handleCopy(e)}
                                                    />
                                                    <div className="label">
                                                        {t('pages.invoiceCard.dropdownItems.copy').text}
                                                    </div>
                                                </Icon>
                                            </Link>
                                            <Icon>
                                                <FontAwesomeIcon
                                                    icon={faMinusCircle}
                                                    style={{ color: colors.gray }}
                                                    onClick={(e) => handleCancel && handleCancel(e)}
                                                />
                                                <div className="label">
                                                    {t('pages.invoiceCard.dropdownItems.cancel').text}
                                                </div>
                                            </Icon>
                                            {!eInvoice && (
                                                <Icon>
                                                    <FontAwesomeIcon
                                                        icon={faTrashCan}
                                                        style={{ color: colors.danger }}
                                                        onClick={(e) => handleDelete && handleDelete(e)}
                                                    />
                                                    <div className="label">
                                                        {t('pages.invoiceCard.dropdownItems.delete').text}
                                                    </div>
                                                </Icon>
                                            )}
                                            <Icon>
                                                <FontAwesomeIcon
                                                    icon={faEnvelope}
                                                    style={{ color: colors.blue }}
                                                    onClick={(e) => handleSend && handleSend(e)}
                                                />
                                                <div className="label">
                                                    {t('pages.invoiceCard.dropdownItems.send').text}
                                                </div>
                                            </Icon>
                                            {!isAdvance ? (
                                                <Icon>
                                                    <FontAwesomeIcon
                                                        icon={faCheckCircle}
                                                        style={{ color: colors.green }}
                                                        onClick={(e) => handlePayment && handlePayment(e)}
                                                    />
                                                    <div className="label">
                                                        {t('pages.invoiceCard.dropdownItems.payment').text}
                                                    </div>
                                                </Icon>
                                            ) : null}
                                            <FontAwesomeIcon icon={faE} style={{ color: colors.gray }} />
                                            {!isAdvance ? (
                                                <Icon>
                                                    <FontAwesomeIcon
                                                        icon={faBell}
                                                        style={{ color: colors.purple }}
                                                        onClick={(e) => handleReminder && handleReminder(e)}
                                                    />
                                                    <div className="label">
                                                        {t('pages.invoices.sendModal.send_reminder').text}
                                                    </div>
                                                </Icon>
                                            ) : null}
                                        </>
                                    ) : cardData?.status === 'partially' ? (
                                        <>
                                            {eInvoice && cardData.eInvoice.data.status === 'Sent' && (
                                                <Icon>
                                                    <FontAwesomeIcon
                                                        icon={faRefresh}
                                                        style={{ color: colors.purple }}
                                                        onClick={(e) => handleRefresh && handleRefresh(e)}
                                                    />
                                                    <div className="label">
                                                        {t('pages.invoiceCard.dropdownItems.refresh').text}
                                                    </div>
                                                </Icon>
                                            )}
                                            <Icon>
                                                <FontAwesomeIcon
                                                    icon={faDownload}
                                                    style={{ color: colors.blue }}
                                                    onClick={(e) => handleDownload && handleDownload(e)}
                                                />
                                                <div className="label">
                                                    {t('pages.invoiceCard.dropdownItems.download').text}
                                                </div>
                                            </Icon>
                                            <Link to={`/e-invoices/edit/${cardData.id}`}>
                                                <Icon>
                                                    <FontAwesomeIcon
                                                        icon={faClone}
                                                        style={{ color: colors.purple }}
                                                        onClick={(e) => handleCopy && handleCopy(e)}
                                                    />
                                                    <div className="label">
                                                        {t('pages.invoiceCard.dropdownItems.copy').text}
                                                    </div>
                                                </Icon>
                                            </Link>
                                            {!eInvoice && (
                                                <Icon>
                                                    <FontAwesomeIcon
                                                        icon={faTrashCan}
                                                        style={{ color: colors.danger }}
                                                        onClick={(e) => handleDelete && handleDelete(e)}
                                                    />
                                                    <div className="label">
                                                        {t('pages.invoiceCard.dropdownItems.delete').text}
                                                    </div>
                                                </Icon>
                                            )}
                                            <Icon>
                                                <FontAwesomeIcon
                                                    icon={faEnvelope}
                                                    style={{ color: colors.blue }}
                                                    onClick={(e) => handleSend && handleSend(e)}
                                                />
                                                <div className="label">
                                                    {t('pages.invoiceCard.dropdownItems.send').text}
                                                </div>
                                            </Icon>
                                            {!isAdvance ? (
                                                <Icon>
                                                    <FontAwesomeIcon
                                                        icon={faCheckCircle}
                                                        style={{ color: colors.green }}
                                                        onClick={(e) => handlePayment && handlePayment(e)}
                                                    />
                                                    <div className="label">
                                                        {t('pages.invoiceCard.dropdownItems.payment').text}
                                                    </div>
                                                </Icon>
                                            ) : null}
                                            {eInvoice && <FontAwesomeIcon icon={faE} style={{ color: colors.gray }} />}
                                            {eAdvanceWithPrepaids && isCanceledPaidInvoice ? (
                                                <Icon>
                                                    <FontAwesomeIcon
                                                        icon={faEraser}
                                                        style={{ color: colors.danger }}
                                                        onClick={(e) => handleRemovePayments && handleRemovePayments(e)}
                                                    />
                                                    <div className="label">
                                                        {t('pages.invoiceCard.dropdownItems.removePayment').text}
                                                    </div>
                                                </Icon>
                                            ) : null}
                                        </>
                                    ) : cardData?.status === 'cancelled' || cardData?.status === 'closed' ? (
                                        <>
                                            {eInvoice && (
                                                <Icon>
                                                    <FontAwesomeIcon
                                                        icon={faRefresh}
                                                        style={{ color: colors.purple }}
                                                        onClick={(e) => handleRefresh && handleRefresh(e)}
                                                    />
                                                    <div className="label">
                                                        {t('pages.invoiceCard.dropdownItems.refresh').text}
                                                    </div>
                                                </Icon>
                                            )}
                                            <Icon>
                                                <FontAwesomeIcon
                                                    icon={faDownload}
                                                    style={{ color: colors.blue }}
                                                    onClick={(e) => handleDownload && handleDownload(e)}
                                                />
                                                <div className="label">
                                                    {t('pages.invoiceCard.dropdownItems.download').text}
                                                </div>
                                            </Icon>
                                            <Link to={`/e-invoices/edit/${cardData.id}`}>
                                                <Icon>
                                                    <FontAwesomeIcon
                                                        icon={faClone}
                                                        style={{ color: colors.purple }}
                                                        onClick={(e) => handleCopy && handleCopy(e)}
                                                    />
                                                    <div className="label">
                                                        {t('pages.invoiceCard.dropdownItems.copy').text}
                                                    </div>
                                                </Icon>
                                            </Link>
                                            {!eInvoice && (
                                                <Icon>
                                                    <FontAwesomeIcon
                                                        icon={faTrashCan}
                                                        style={{ color: colors.danger }}
                                                        onClick={(e) => handleDelete && handleDelete(e)}
                                                    />
                                                    <div className="label">
                                                        {t('pages.invoiceCard.dropdownItems.delete').text}
                                                    </div>
                                                </Icon>
                                            )}
                                            <Icon>
                                                <FontAwesomeIcon
                                                    icon={faEnvelope}
                                                    style={{ color: colors.blue }}
                                                    onClick={(e) => handleSend && handleSend(e)}
                                                />
                                                <div className="label">
                                                    {t('pages.invoiceCard.dropdownItems.send').text}
                                                </div>
                                            </Icon>
                                            {eInvoice && <FontAwesomeIcon icon={faE} style={{ color: colors.gray }} />}
                                        </>
                                    ) : (
                                        <></>
                                    )}
                                </ActionWithoutDropdown>
                            )}
                        </div>
                    </ActionWrapper>
                ) : (
                    <></>
                )}
            </CardContent>
        </StyledInvoiceCard>
    );
};
const StyledInvoiceCard = styled.div`
    margin-bottom: 10px;
    padding: 16px 20px;
    border-radius: 5px;
    width: 100%;

    background: var(--white);
    border: 1px solid var(--purple);
    transition: all 0.5s ease-in-out;
    &:hover {
        box-shadow: 0 0 5px 5px #eee;
        .info-wrapper {
            opacity: 0.7;
        }
    }
    @media screen and (max-width: ${MQ_BREAKPOINTS.tablet}) {
        align-items: start;
    }
    &.card-display-grid {
        display: flex;
        flex-direction: column;
        .card-content {
            grid-template-columns: 1fr;
            width: 100%;
            .info-wrapper {
                grid-template-columns: 2fr 2fr;
                > div {
                    &:nth-of-type(1) {
                        order: 3;
                        grid-column: 1 / 4;
                    }
                    &:nth-of-type(2) {
                        order: 2;
                        margin-bottom: 20px;
                        display: flex;
                        flex-direction: column;
                        align-items: flex-end;
                    }
                    &:nth-of-type(3) {
                        order: 4;
                        grid-column: 1 / 4;
                    }
                    &:nth-of-type(4) {
                        order: 4;
                    }
                    &.status-col {
                        order: 1;
                        margin-bottom: 20px;
                    }
                }
            }
        }
    }
`;

const CardContent = styled.div`
    display: grid;
    align-items: center;
    grid-template-columns: 3fr 1fr;
    @media screen and (max-width: ${MQ_BREAKPOINTS.laptopL}) {
        grid-template-columns: 8fr 1fr;
    }

    div {
        color: var(--black);
        &.number {
            text-transform: capitalize;
        }
        &.status {
            text-transform: capitalize;
            &.sent {
                span {
                    background: var(--blue);
                }
            }
            &.paid,
            &.sending {
                span {
                    background: var(--gray);
                }
            }
            &.cancelled {
                span {
                    background: var(--danger);
                }
            }
            &.partially {
                span {
                    background: linear-gradient(90deg, var(--green), var(--blue));
                }
            }
            &.approved {
                span {
                    background: var(--turquoise);
                }
            }
            &.storno {
                span {
                    background: var(--danger);
                }
            }
            &.rejected {
                span {
                    background: var(--danger);
                }
            }
            &.mistake {
                span {
                    background: var(--danger);
                }
            }
            &.null {
                span {
                    background: var(--gray);
                }
            }
            span {
                padding: 0 10px 2px;
                background: var(--gray);
                color: var(--white);
                border-radius: 50px;
                font-size: 12px;
            }
        }
        &.action {
            display: flex;
            justify-content: flex-end;
        }
    }
`;

const InfoWrapper = styled.div`
    grid-template-columns: 2fr 1fr 1fr 1fr 1fr 1fr;
    display: grid;
    align-items: center;
    @media screen and (max-width: ${MQ_BREAKPOINTS.tablet}) {
        grid-template-columns: 2fr 2fr;
    }

    .label {
        font-size: 12px;
        color: var(--gray);
        line-height: 25px;
    }
`;

const ActionWrapper = styled.div`
    display: flex;
    justify-content: flex-end;

    div {
        &.action {
            justify-content: center;
            margin-top: 20px;
        }
    }
`;

const Action = styled.div`
    position: relative;
    .dots {
        font-size: 25px;
        padding: 10px 17px 7px;
        transition: all 0.5s ease-in-out;
        &:hover {
            background: var(--light-gray);
        }
    }
`;

const DropdownContainer = styled.div`
    position: absolute;
    z-index: 1;
    right: 0;
    &.down {
        top: 55px;
    }
    &.up {
        bottom: 55px;
    }
    p {
        font-size: 16px;
        line-height: 20px;
    }
    .dropdown-container {
        svg {
            height: 20px;
            width: 20px;
        }
    }
`;

const ActionWithoutDropdown = styled.div`
    display: flex;
    gap: 10px;
`;

export default EInvoiceCard;
