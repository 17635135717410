import { FunctionComponent, useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark, faPaperclip, faPaperPlane, faTrashCan } from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components';
import colors from '../../../../global/colors';
import useEmailValidation from '../../common/useEmailValidation';
import useProfileStore from '../../../../store/profile';
import { ObjectKeys } from '../../../../types/objectKeys';
import Button, { ButtonVariant } from '../../../../components/button';
import Input, { InputComponentType } from '../../../../components/input';
import TextArea from '../../../../components/input/textarea';
import communication from '../../../../communication';
import Checkbox from '../../../../components/checkbox';
import useSendMeEmail from '../../../../hooks/useSendMeEmail';
import { generateQrCode } from '../../../../communication/qr';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

interface Props {
    t: Function;
    formData: ObjectKeys;
    setFormData: Function;
    close: Function;
    success: Function;
    loading: Function;
    id?: string;
    clientEmail: string;
    modalVisible: boolean;
    handleAddNewInvoice?: Function;
    handleUpdateInvoice?: Function;
    qrCode?: string;
    errorMessage: Function;
    totalAvance?: String;
}

const SendModal: FunctionComponent<Props> = ({
    t,
    formData,
    setFormData,
    close,
    success,
    loading,
    id,
    clientEmail,
    handleAddNewInvoice,
    handleUpdateInvoice,
    qrCode,
    errorMessage,
    modalVisible,
    totalAvance,
}) => {
    const [showError, setShowError] = useState(false);
    const [showEmails, setShowEmails] = useState(true);
    const [emailsChanged, setEmailsChanged] = useState(false);
    const [emails, setEmails] = useState<Array<string>>(clientEmail ? [clientEmail] : []);
    const [sendMe, setSendMe] = useState(false);
    const [addedFiles, setAddedFiles] = useState<Array<File>>([]);
    const [userEmail, setUserEmail] = useState('');
    const isEmailValid = useEmailValidation(formData.recipient, emails);
    const { profile } = useProfileStore();
    const [fromInput, setFromInput] = useState(false);
    useEffect(() => {
        setFormData({
            recipient: '',
            subject: '',
            message: '',
        });
    }, [modalVisible]);

    const handleDataToSend = async (invoiceId?: string, qr_code?: string): Promise<void> => {
        if (invoiceId) {
            loading(true);
            const form = new FormData();
            for (let i = 0; i < addedFiles.length; i++) {
                const file = addedFiles[i];
                form.append('attachments[' + i + ']', file);
            }
            const requestData = {
                message: formData.message,
                subject: formData.subject,
                emails: emails,
                qr_code:
                    qr_code !== '/'
                        ? `data:image/jpeg;base64,${qr_code}`
                        : qrCode
                        ? `data:image/jpeg;base64,${qrCode}`
                        : '',
            };
            form.append('request_body', JSON.stringify(requestData));
            try {
                communication
                    .sendInvoice(invoiceId, form)
                    .then((res: any) => {
                        if (res.status === 200) {
                            success();
                            close();
                            loading(false);
                        }
                    })
                    .catch((e: any) => {
                        errorMessage(e.response.data.message);
                        loading(false);
                    });
            } catch (e: any) {
                errorMessage(e.response.data.message);
                loading(false);
            }
        } else {
            setShowError(true);
        }
    };

    const sendEmail = async (): Promise<void> => {
        if (isEmailValid === '' && emails.length > 0) {
            if (handleAddNewInvoice !== undefined) {
                const newInvoice = await handleAddNewInvoice();
                if (newInvoice) {
                    if (!newInvoice.foreign) {
                        try {
                            generateQrCode(
                                newInvoice.agency,
                                newInvoice.client,
                                newInvoice.amount,
                                newInvoice.number,
                                newInvoice.account,
                                newInvoice.currency,
                                true,
                                newInvoice.number ? `00${newInvoice?.number?.replace('/', '-')}` : null,
                                '221',
                            )
                                .then((response: ObjectKeys | any) => {
                                    if (response.i) {
                                        handleDataToSend(newInvoice.id, response.i);
                                    } else {
                                        handleDataToSend(newInvoice.id, '/');
                                    }
                                })
                                .catch((error: any) => {
                                    console.error(error);
                                    handleDataToSend(newInvoice.id, '/');
                                });
                        } catch (e: any) {
                            console.error(e);
                            handleDataToSend(newInvoice.id, '/');
                        }
                    } else {
                        handleDataToSend(newInvoice.id, '/');
                    }
                }
            } else if (handleUpdateInvoice !== undefined) {
                if (handleUpdateInvoice !== undefined) {
                    const updatedInvoice = await handleUpdateInvoice();
                    if (updatedInvoice) {
                        if (updatedInvoice.type !== 'foreign') {
                            try {
                                generateQrCode(
                                    updatedInvoice.original_data.agency.full_name,
                                    updatedInvoice.client.data.company_name,
                                    totalAvance ? totalAvance.toString() : updatedInvoice.total_for_payment_in_rsd,
                                    updatedInvoice.invoice_number,
                                    updatedInvoice.bank_account,
                                    updatedInvoice.currency,
                                    true,
                                    updatedInvoice.invoice_number
                                        ? `00${updatedInvoice?.invoice_number?.replace('/', '-')}`
                                        : null,
                                    '221',
                                )
                                    .then((response: ObjectKeys | any) => {
                                        if (response.i) {
                                            handleDataToSend(updatedInvoice.id, response.i);
                                        }
                                    })
                                    .catch((error: any) => {
                                        console.error(error);
                                        handleDataToSend(updatedInvoice.id, '/');
                                    });
                            } catch (e: any) {
                                console.error(e);
                                handleDataToSend(updatedInvoice.id, '/');
                            }
                        } else {
                            handleDataToSend(updatedInvoice.id, '/');
                        }
                    }
                }
            } else {
                handleDataToSend(id, '/');
            }
        } else setShowError(true);
    };

    const handleRemoveEmail = (e: string): void => {
        const reducedEmails = emails;
        const index = reducedEmails.indexOf(e);

        if (index > -1) {
            reducedEmails.splice(index, 1);
        }
        setEmails(reducedEmails);
        setEmailsChanged(!emailsChanged);
    };

    useSendMeEmail(
        setUserEmail,
        setShowEmails,
        setEmails,
        setEmailsChanged,
        handleRemoveEmail,
        profile,
        sendMe,
        emailsChanged,
        userEmail,
        emails,
        fromInput,
        setFromInput,
    );

    const handleAttachInvoice = (event: any): void => {
        if (event && event.target && event.target.files && event.target.files[0]) {
            console.log(event.target.files[0]);
            const file = event.target.files[0];
            const fileSize = file.size / 1024 / 1024;
            if (fileSize > 5) {
                toast.error(t('pages.error.overSize').text);
                event.target.value = null;
            } else {
                setAddedFiles([...addedFiles, file]);
            }
        }
    };

    const handleRemoveFile = (indexToRemove: number): void => {
        const updatedFiles = addedFiles.filter((file, index) => index !== indexToRemove);
        setAddedFiles(updatedFiles);
    };

    return (
        <>
            <ToastContainer />
            <Container>
                <Header>
                    <h2>{t('pages.invoices.sendModal.title').text}</h2>
                    <Icon onClick={() => close()}>
                        <FontAwesomeIcon icon={faXmark} style={{ color: 'var(--gray)' }} />
                    </Icon>
                    <p>{t('pages.invoices.sendModal.description').text}</p>
                </Header>
                <label>{t('pages.invoices.sendModal.recipient').text}</label>
                <div className="emails">
                    {showEmails &&
                        emails.length > 0 &&
                        emails
                            .filter((email: string) => email !== '')
                            .map((email: string) => (
                                <Email key={email}>
                                    {email}
                                    <span onClick={() => handleRemoveEmail(email)}>x</span>
                                </Email>
                            ))}
                </div>
                <EmailFieldWrapper>
                    <Input
                        className="email"
                        type={InputComponentType.Text}
                        value={formData.recipient ? formData.recipient : ''}
                        onChange={(value: string) => {
                            setFormData({ ...formData, recipient: value });
                        }}
                        onEnter={() => {
                            if (formData.recipient !== '' && isEmailValid === '') {
                                setShowEmails(true);
                                setEmails([...emails, formData.recipient]);
                                setFormData({ ...formData, recipient: '' });
                            }
                        }}
                        validation={showError ? isEmailValid : ''}
                        handleBlurAction={() => {
                            setShowError(true);
                            if (emails.includes(formData.recipient)) {
                                setFormData({ ...formData, recipient: '' });
                                return;
                            }
                            if (formData.recipient !== '' && isEmailValid === '') {
                                setFromInput(true);
                                setShowEmails(true);
                                setEmails([...emails, formData.recipient]);
                                setFormData({ ...formData, recipient: '' });
                            }
                        }}
                        hideBlurOnChange
                    />
                </EmailFieldWrapper>
                <Input
                    type={InputComponentType.Text}
                    label={t('pages.invoices.sendModal.subject').text}
                    value={formData.subject ? formData.subject : ''}
                    onChange={(value: string) => {
                        setFormData({ ...formData, subject: value });
                    }}
                />
                <TextArea
                    label={t('pages.invoices.sendModal.message').text}
                    value={formData.message ? formData.message : ''}
                    onChange={(value: string) => {
                        setFormData({ ...formData, message: value });
                    }}
                />
                <Footer>
                    <div>
                        <Icon className="attach">
                            <FontAwesomeIcon icon={faPaperclip} style={{ color: 'var(--gray)', marginRight: '3px' }} />
                            {t('pages.invoices.sendModal.attached').text}
                            {addedFiles.length > 0 ? (
                                <AttachedFiles>
                                    {addedFiles.map((file: ObjectKeys, index: number) => {
                                        return (
                                            <div key={index} className="attached-files">
                                                <p>
                                                    <FontAwesomeIcon
                                                        icon={faPaperclip}
                                                        style={{ color: 'var(--gray)', marginRight: '3px' }}
                                                    />
                                                    {file.name}
                                                </p>
                                                <button
                                                    onClick={() => handleRemoveFile(index)}
                                                    className="delete_button"
                                                >
                                                    <FontAwesomeIcon
                                                        icon={faTrashCan}
                                                        style={{ color: colors.danger }}
                                                    />
                                                </button>
                                            </div>
                                        );
                                    })}
                                </AttachedFiles>
                            ) : (
                                <></>
                            )}
                        </Icon>
                        <div className="send-copy">
                            <Checkbox onChange={() => setSendMe(!sendMe)} />
                            {t('pages.invoices.sendModal.sendMeCopy').text}
                        </div>
                    </div>

                    <UploadFiles>
                        <input type="file" onChange={(e: any) => handleAttachInvoice(e)} />
                        <FontAwesomeIcon icon={faPaperclip} style={{ color: 'var(--black)', marginRight: '3px' }} />
                        <span>{t('pages.invoices.sendModal.attach').text}</span>
                    </UploadFiles>
                </Footer>
                <div className="button-container">
                    <Button
                        variant={ButtonVariant.solid}
                        color={colors.purple}
                        className="small"
                        size={200}
                        onClick={sendEmail}
                    >
                        <FontAwesomeIcon icon={faPaperPlane} style={{ color: 'var(--white)', marginRight: '10px' }} />
                        {t('pages.invoices.sendModal.send').text}
                    </Button>
                </div>
            </Container>
        </>
    );
};

export default SendModal;
const Container = styled.div`
    .button-container {
        margin-top: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .input-container {
        width: 100%;
    }
    label {
        color: var(--gray);
    }
    .emails {
        margin-top: 10px;
    }
`;

const EmailFieldWrapper = styled.div`
    .validation-message {
        color: var(--danger);
        font-size: 12px;
    }
`;
const Email = styled.div`
    display: inline-block;
    padding: 5px 10px;
    text-decoration: none;
    background: var(--purple);
    color: var(--white);
    margin-bottom: 6px;
    margin-right: 6px;
    font-size: 14px;
    span {
        margin-left: 10px;
        cursor: pointer;
    }
`;
const Header = styled.div`
    display: grid;
    grid-template-columns: 95% 5%;
    h2 {
        font-size: 24px;
        font-weight: 400;
        text-align: left;
    }
    p {
        width: 90%;
        font-size: 14px;
        line-height: 20px;
        margin-top: 10px;
        color: var(--gray);
        margin-bottom: 30px;
    }
`;

const Footer = styled.div`
    > div {
        margin-top: 20px;
        font-size: 13px;
        color: var(--gray);
        display: flow-root;
        .send-copy {
            float: right;
            display: flex;
        }
    }
`;

const Icon = styled.div`
    float: right;
    font-size: 30px;
    margin-top: -8px;
    &.attach {
        font-size: 13px;
        color: var(--gray);
        float: left;
        margin-top: 0px;
    }
`;
const UploadFiles = styled.label`
    cursor: pointer;
    font-size: 13px;
    span {
        font-size: 13px;
        color: var(--black);
    }
    &:hover {
        span {
            color: var(--purple);
        }
    }
    input[type='file'] {
        display: none;
    }
`;
const AttachedFiles = styled.div`
    margin-top: 10px;
    p {
        font-size: 13px;
        color: var(--gray);
        margin-bottom: 5px;
    }
    .attached-files {
        display: flex;
    }
    .delete_button {
        border: none;
        background: none;
        padding-left: 10px;
        margin-top: -5px;
    }
`;
