import { useEffect, useState } from 'react';
import styled from 'styled-components';
import communication from '../../../../communication';
import { ObjectKeys } from '@/types/objectKeys';
import Loader from '../../../../components/loader';
import useAgencyStore from '../../../../store/agency';
import useTranslations from '../../../../hooks/useTranslation';
import useLanguageStore from '../../../../store/language';
import { formatTwoDecimals } from '../../../../functions/format';
import { useNavigate, useParams } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { StepControlButton } from '../../../../components/stepControlButton';
import { MQ_BREAKPOINTS } from '../../../../constants/breakpoints';

interface StepTwoProps {
    cart: ObjectKeys;
    nextStep: Function;
    previousStep: Function;
    updatedCart: Function;
}

const StepTwo: React.FC<StepTwoProps> = ({ cart, nextStep, previousStep, updatedCart }) => {
    const { currentLang } = useLanguageStore();
    const t = useTranslations(currentLang);
    const navigate = useNavigate();
    const paramPackage = useParams().cartId;

    const [loader, setLoader] = useState<boolean>(false);

    const [currentStep, setCurrentStep] = useState(paramPackage && !paramPackage.includes('-') ? 2 : 1);

    const { agency } = useAgencyStore();
    const isTrialUser = agency.user.data.trial;
    const userPackage = agency.user.data.payment_package.data;
    const minimumMonths = agency.user.data.upgrade_package_data.upgrade_min_months;
    const [prepaidDiscount, setPrepaidDiscount] = useState(
        agency.user.data.upgrade_package_data.upgrade_left_over_amount,
    );

    const [packages, setPackages] = useState<ObjectKeys[]>([]);

    const [selectedPackageOption, setSelectedPackageOption] = useState<ObjectKeys>({});

    const [downgrade, setDowngrade] = useState<boolean>(false);

    const [renderKey, setRenderKey] = useState<number>(0);
    const [renderCartKey, setRenderCartKey] = useState<number>(0);

    useEffect(() => {
        setRenderCartKey(renderCartKey + 1);
        setRenderKey(renderKey + 1);
    }, [cart]);

    const getAllPackages = (): void => {
        setLoader(true);
        communication
            .getAllPackages()
            .then((response: ObjectKeys) => {
                const activePackages = response.data.data.filter((item: ObjectKeys) => item.active);
                setPackages(activePackages);
                if (paramPackage && !paramPackage.includes('-')) {
                    const selectedPackage = activePackages.find((item: ObjectKeys) => item.name === paramPackage);
                    setSelectedPackageOption(selectedPackage || activePackages[0]);
                    const period = selectedPackage.months > minimumMonths ? selectedPackage.months : minimumMonths;
                    updatedCart({
                        ...cart,
                        items: [
                            {
                                id: selectedPackage.id,
                                type: 'package',
                                price: selectedPackage.monthly,
                                months: period,
                            },
                        ],
                    });
                    setLoader(false);
                } else {
                    const clickedPackage = activePackages.find((item: ObjectKeys) => item.id === cart.items[0].id);
                    setSelectedPackageOption(clickedPackage || activePackages[0]);
                    setLoader(false);
                }
            })
            .catch((error: any) => {
                setLoader(false);
                console.error('Error:', error);
            });
    };

    const handleNextStep = (): void => {
        setCurrentStep(currentStep + 1); // Move to the next step
        nextStep();
        navigate('/settings/subscription/3');
    };

    const handlePreviousStep = (): void => {
        setCurrentStep(currentStep - 1); // Move to the previous step
        previousStep();
        navigate('/settings/subscription/1');
    };

    const handlePeriodChange = (period: string): void => {
        let months;
        switch (period) {
            case 'month':
                months = 1;
                break;
            case 'halfYear':
                months = 6;
                break;
            case 'year':
                months = 12;
                break;
            default:
                months = 1;
        }

        updatedCart({
            ...cart,
            items: [
                {
                    id: selectedPackageOption.id,
                    type: 'package',
                    price: selectedPackageOption.monthly,
                    months: months,
                },
            ],
        });
        setRenderKey(renderKey + 1);
    };

    useEffect(() => {
        getAllPackages();
    }, []);

    useEffect(() => {
        if (downgrade) {
            setPrepaidDiscount(0);
            setRenderKey(renderKey + 1);
        } else {
            setPrepaidDiscount(agency.user.data.upgrade_package_data.upgrade_left_over_amount);
            setRenderKey(renderKey + 1);
        }
    }, [downgrade]);

    useEffect(() => {
        if (!selectedPackageOption) return;
        if (isTrialUser) {
            setDowngrade(false);
        } else {
            if (selectedPackageOption.monthly <= userPackage.monthly) {
                setDowngrade(true);
            } else setDowngrade(false);
        }
    }, [selectedPackageOption]);

    const calculatePackageDiscount = (entireCart: ObjectKeys): string => {
        const total = entireCart.items[0].price * entireCart.items[0].months;
        const packageFromCart = packages.find((item: ObjectKeys) => item.id === entireCart.items[0].id);
        const period = entireCart.items[0].months;
        const discount =
            period === 12 ? packageFromCart?.year_discount : period === 6 ? packageFromCart?.half_year_discount : 0;
        const value = total * (discount / 100);
        return formatTwoDecimals(value.toString());
    };

    useEffect(() => {
        if (selectedPackageOption) {
            const discount =
                cart.items[0].months === 6
                    ? selectedPackageOption.half_year_discount
                    : cart.items[0].months === 12
                    ? selectedPackageOption.year_discount
                    : 0;
            const period = cart.items[0].months;
            updatedCart({
                ...cart,
                total_price:
                    selectedPackageOption.monthly * period - (selectedPackageOption.monthly * period * discount) / 100,
                total_price_discounted:
                    selectedPackageOption.monthly * period -
                    (selectedPackageOption.monthly * period * discount) / 100 -
                    prepaidDiscount,
            });
        }
    }, [cart.items, prepaidDiscount, selectedPackageOption]);

    return (
        <>
            <ToastContainer />
            {loader && <Loader />}
            {selectedPackageOption && cart && (
                <>
                    <ContentSecondStep>
                        <div className="left-right-page-wrapper">
                            <div className="leftPage">
                                <div className="packages">
                                    {packages?.map((item, index) => (
                                        <Package
                                            key={index}
                                            isSelected={item.id === selectedPackageOption.id}
                                            onClick={() => {
                                                const period =
                                                    item.months > minimumMonths ? item.months : minimumMonths;
                                                setSelectedPackageOption(packages[index]);
                                                updatedCart({
                                                    ...cart,
                                                    items: [
                                                        {
                                                            ...cart.items[0],
                                                            id: packages[index].id,
                                                            price: packages[index].monthly,
                                                            months: period,
                                                        },
                                                    ],
                                                });
                                            }}
                                        >
                                            {item.name}
                                        </Package>
                                    ))}
                                </div>
                                <div key={renderKey}>
                                    <div>
                                        {selectedPackageOption.name === 'Basic' && (minimumMonths < 12 || downgrade) ? (
                                            <span className="chooseSubscription">
                                                <input
                                                    type="radio"
                                                    // value={index}
                                                    checked={cart.items[0].months === 1}
                                                    onChange={() => {
                                                        handlePeriodChange('month');
                                                    }}
                                                />
                                                <div className="item">
                                                    <p>
                                                        {t('pages.agency.subscription.one_month').text +
                                                            ' ' +
                                                            t(
                                                                'pages.agency.subscription.subscription',
                                                            ).text.toLowerCase()}
                                                    </p>
                                                    <span style={{ display: 'inline-flex' }}>
                                                        <p>
                                                            {formatTwoDecimals(
                                                                selectedPackageOption.monthly.toString(),
                                                            )}{' '}
                                                            RSD
                                                        </p>
                                                    </span>
                                                </div>
                                            </span>
                                        ) : null}
                                        {minimumMonths < 12 ||
                                        downgrade ||
                                        selectedPackageOption.id === userPackage.id ? (
                                            <span className="chooseSubscription">
                                                <input
                                                    type="radio"
                                                    // value={index}
                                                    checked={cart.items[0].months === 6}
                                                    onChange={() => {
                                                        handlePeriodChange('halfYear');
                                                    }}
                                                />
                                                <div className="item">
                                                    <p>
                                                        {t('pages.agency.subscription.half_year').text +
                                                            ' ' +
                                                            t(
                                                                'pages.agency.subscription.subscription',
                                                            ).text.toLowerCase()}
                                                    </p>
                                                    <span style={{ display: 'inline-flex' }}>
                                                        <p className="crossed">
                                                            {formatTwoDecimals(
                                                                (selectedPackageOption.monthly * 6).toString(),
                                                            )}{' '}
                                                            RSD
                                                        </p>
                                                        <p>{' / '}</p>
                                                        <p>
                                                            {formatTwoDecimals(
                                                                (
                                                                    selectedPackageOption.monthly * 6 -
                                                                    (selectedPackageOption.monthly *
                                                                        6 *
                                                                        selectedPackageOption.half_year_discount) /
                                                                        100
                                                                ).toString(),
                                                            )}
                                                            RSD
                                                        </p>
                                                    </span>
                                                </div>
                                            </span>
                                        ) : null}

                                        <span className="chooseSubscription">
                                            <input
                                                type="radio"
                                                // value={index}
                                                checked={cart.items[0].months === 12}
                                                onChange={() => {
                                                    handlePeriodChange('year');
                                                }}
                                            />
                                            <div className="item">
                                                <p>
                                                    {t('pages.agency.subscription.one_year').text +
                                                        ' ' +
                                                        t('pages.agency.subscription.subscription').text.toLowerCase()}
                                                </p>
                                                <span style={{ display: 'inline-flex' }}>
                                                    <p className="crossed">
                                                        {formatTwoDecimals(
                                                            (selectedPackageOption.monthly * 12).toString(),
                                                        )}{' '}
                                                        RSD
                                                    </p>
                                                    <p>{' / '}</p>
                                                    <p>
                                                        {formatTwoDecimals(
                                                            (
                                                                selectedPackageOption.monthly * 12 -
                                                                (selectedPackageOption.monthly *
                                                                    12 *
                                                                    selectedPackageOption.year_discount) /
                                                                    100
                                                            ).toString(),
                                                        )}
                                                        , RSD
                                                    </p>
                                                </span>
                                            </div>
                                        </span>
                                    </div>
                                </div>
                                <div>
                                    <div className="note">
                                        {t('pages.agency.subscription.note').text}{' '}
                                        <span className="wallet-link" onClick={() => navigate('/settings/wallet')}>
                                            {t('pages.agency.subscription.wallet').text}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="rightMain">
                                <div className="rightPage" key={renderCartKey}>
                                    <div className="title">
                                        <h2>{t('pages.agency.subscription.chosenPackage').text}</h2>
                                        <h3>
                                            {selectedPackageOption.name}/
                                            {cart.items[0].months === 1
                                                ? t('pages.agency.subscription.one_month').text
                                                : cart.items[0].months === 6
                                                ? t('pages.agency.subscription.half_year').text
                                                : t('pages.agency.subscription.one_year').text}
                                        </h3>
                                    </div>
                                    <div className="table">
                                        <div>
                                            <p className="noBold">{t('pages.agency.subscription.price').text}</p>
                                            <p>
                                                {formatTwoDecimals(
                                                    (cart.items[0].price * cart.items[0].months).toString(),
                                                )}{' '}
                                                RSD
                                            </p>
                                        </div>
                                        <div>
                                            <p className="noBold">{t('pages.agency.subscription.discount').text}</p>
                                            <p>{calculatePackageDiscount(cart)} RSD</p>
                                        </div>
                                        <div>
                                            <p className="noBold">{t('pages.agency.subscription.paid').text}</p>
                                            <p>{formatTwoDecimals(prepaidDiscount?.toString())} RSD</p>
                                        </div>
                                    </div>
                                    <div className="total">
                                        <p className="noBold">{t('pages.agency.subscription.total').text}</p>
                                        {cart.total_price_discounted ? (
                                            <p>{formatTwoDecimals(cart.total_price_discounted?.toString())} RSD</p>
                                        ) : null}
                                    </div>
                                </div>
                                <p className="downgrade-text">{t('pages.agency.subscription.downgrade_note').text}</p>
                            </div>
                        </div>
                        <div className="buttons-wrapper">
                            <StepControlButton step={'previous'} handleClick={handlePreviousStep} />
                            <StepControlButton step={'next'} handleClick={handleNextStep} />
                        </div>
                    </ContentSecondStep>
                </>
            )}
        </>
    );
};
const ContentSecondStep = styled.div`
    display: flex;
    flex-direction: column;
    .left-right-page-wrapper {
        display: flex;
        @media only screen and (max-width: ${MQ_BREAKPOINTS.tablet}) {
            flex-direction: column;
        }
        .rightMain {
            height: fit-content;
            max-width: ${MQ_BREAKPOINTS.mobileL};
            padding: 30px 0;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            @media only screen and (max-width: ${MQ_BREAKPOINTS.tablet}) {
                width: 100%;
                max-width: 100%;
            }
        }
        .leftPage {
            flex-grow: 1;

            padding: 30px;
            @media only screen and (max-width: ${MQ_BREAKPOINTS.tablet}) {
                width: 100%;
                max-width: 100%;
            }
            .packages {
                display: flex;
                flex-wrap: wrap;

                gap: 20px;
                padding: 10px;
                margin-bottom: 1rem;
                @media only screen and (min-width: ${MQ_BREAKPOINTS.laptopL}) {
                    min-width: 510px;
                }
                @media only screen and (max-width: ${MQ_BREAKPOINTS.tablet}) {
                    flex-direction: column;
                    align-items: center;
                }
            }
            h2 {
                font-size: 24px;
                font-weight: bold;
                color: var(--purple);
            }
            .chooseSubscription {
                display: flex;
                align-items: center;
                gap: 5px;
                padding: 10px;
                font-size: 20px;
                .item {
                    padding-left: 10px;
                    text-align: justify;
                    color: var(--gray);
                }
                .crossed {
                    text-decoration: line-through;
                }
            }
        }
        .rightPage {
            display: flex;
            flex-direction: column;
            align-items: center;
            flex-grow: 1;
            background-color: var(--purple);
            color: white;
            height: fit-content;
            padding: 20px 0;
            @media only screen and (max-width: ${MQ_BREAKPOINTS.tablet}) {
                width: 100%;
                max-width: 100%;
            }
            .title {
                display: flex;
                flex-direction: column;
                align-items: center;
                text-align: center;
                h2 {
                    font-size: 15px;
                    color: white;
                    margin: 10px;
                }
                h3 {
                    font-size: 15px;
                    color: white;
                    margin: 10px;
                }
            }
            .noBold {
                font-weight: normal;
            }

            .table {
                width: 90%;
                padding: 20px 0;
                border-top: 1px solid var(--white);
                border-bottom: 1px solid var(--white);
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 20px;
                margin-top: 20px;

                div {
                    display: flex;
                    justify-content: space-between;
                    width: 100%;
                    p {
                        font-size: 15px;
                    }
                }
            }
            .total {
                margin-top: 20px;
                display: flex;
                justify-content: space-between;
                width: 90%;
                padding: 10px 0;
                @media only screen and (max-width: ${MQ_BREAKPOINTS.tablet}) {
                    padding: 20px 0 20px 0;
                }
                p {
                    font-size: 15px;
                }
            }
        }
        input[type='radio'] {
            accent-color: #9b7fe9;
            height: 25px;
            width: 20px;
            margin-bottom: 10px;
        }
        .note {
            margin-top: 25px;
            font-size: 14px;
            color: var(--gray);
            text-align: center;
            line-height: 20px;
            border-radius: 30px;
            padding: 5px;
            max-width: 520px;
        }
        .wallet-link {
            font-weight: bold;
            cursor: pointer;
        }
        .downgrade-text {
            margin-top: 1rem;
            font-size: 13px;
            color: var(--purple);
            font-style: italic;
        }
    }
    .buttons-wrapper {
        display: flex;
        justify-content: space-between;
        padding: 1rem 0;
    }
`;
const Package = styled.div<{ isSelected: boolean }>`
    width: 100%;
    min-width: 150px;
    max-width: 150px;
    display: flex;
    justify-content: center;
    border: 1px solid var(--purple);
    font-size: 15px;
    padding: 15px;
    border-radius: 0px;
    cursor: pointer;
    background-color: ${(props) => (props.isSelected ? 'var(--purple)' : 'white')};
    color: ${(props) => (props.isSelected ? 'white' : 'var(--purple)')};
`;
export default StepTwo;
