import { FunctionComponent, useState } from 'react';
import styled from 'styled-components';
import useTranslations from '../../../hooks/useTranslation';
import useLanguageStore from '../../../store/language';
import useChangeModalStore from '../../../store/changeModal';
import communication from '../../../communication';
import Button, { ButtonVariant } from '../../../components/button';
import colors from '../../../global/colors';
import Success from '../../../components/successModal';
import Modal from '../../../components/modal';
import Loader from '../../../components/loader';
import SelectDefaultLang from './selectComponent';
import { ObjectKeys } from '../../../types/objectKeys';
import useAgencyStore from '../../../store/agency';
import { MQ_BREAKPOINTS } from '../../../constants/breakpoints';

const DefaultLang: FunctionComponent = () => {
    const { currentLang } = useLanguageStore();
    const t = useTranslations(currentLang);

    const { agency } = useAgencyStore();
    const userLang = agency.user.data.lang;

    const [successfullySaved, setSuccessfullySaved] = useState(false);
    const [loaderVisible, setLoaderVisible] = useState(false);

    const [formData, setFormData] = useState({
        lang: userLang,
    });

    const update = async (): Promise<void> => {
        setLoaderVisible(true);
        if (formData) {
            const name = formData.lang === 'rs' ? 'Srpski' : formData.lang === 'en' ? 'English' : 'Русский';
            useLanguageStore.setState({ currentLangName: name });
            localStorage.setItem('language', name);
            const res = await communication.updateUser(formData, agency.user.data.id);
            if (res.status === 200) {
                setSuccessfullySaved(true);
                await communication.getAgency().then((response: ObjectKeys) => {
                    useAgencyStore.setState({
                        agency: response?.data?.data,
                    });
                });
            }
        }
        setLoaderVisible(false);
        useChangeModalStore.setState({ changeModalVisible: false });
    };

    const handleNameChange = (name: string): void => {
        const langCode = name === 'Srpski' ? 'rs' : name === 'English' ? 'en' : name === 'Русский' ? 'ru' : name;
        if (formData) setFormData({ ...formData, lang: langCode });
    };

    return (
        <>
            {successfullySaved && (
                <Modal modalVisible={true} closeModal={() => setSuccessfullySaved(false)}>
                    <Success
                        close={() => setSuccessfullySaved(false)}
                        message={t('pages.agency.profile.saveSuccess').text}
                    />
                </Modal>
            )}
            {loaderVisible && <Loader />}
            {formData ? (
                <Content>
                    <div className="center">
                        <div className="lang-selector">{t('pages.agency.langSelection.description').text}</div>
                        <div className="lang-selector2">
                            <SelectDefaultLang color="black" onNameChange={handleNameChange} />
                        </div>
                        <div className="button-container">
                            <Button variant={ButtonVariant.solid} color={colors.purple} size={200} onClick={update}>
                                {t('pages.agency.profile.save').text}
                            </Button>
                        </div>
                    </div>
                </Content>
            ) : (
                <Loader />
            )}
        </>
    );
};
export default DefaultLang;

const Content = styled.div`
    .center {
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    text-align: center;
    width: 56%;
    display: inline-block;
    vertical-align: top;
    color: var(--black);
    .lang-selector {
        margin-top: 30px;
        margin-bottom: 30px;
        font-size: 15px;
        color: var(--gray);
    }
    .button-container {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 50px;
        @media screen and (max-width: ${MQ_BREAKPOINTS.tablet}) {
            padding-bottom: 80px;
            margin-top: 20px;
        }
    }
    .bank-number {
        width: 400px;
        position: relative;
    }
    .input-container {
        width: -webkit-fill-available;
        @media screen and (max-width: ${MQ_BREAKPOINTS.tablet}) {
            margin-bottom: 0px;
            label {
                margin-bottom: 10px;
            }
        }
    }

    input {
        @media screen and (max-width: ${MQ_BREAKPOINTS.tablet}) {
            border: 1px solid var(--border-color);
            background: var(--white);
            margin-bottom: 20px;
        }
    }

    p {
        font-size: 15px;
        color: var(--gray);
        display: block;
        width: 100%;
        margin: 0;
        padding: 0 0 2px;
        border: 0;
        margin-bottom: 10px;
        :hover {
            color: var(--purple);
        }
    }

    @media screen and (min-width: ${MQ_BREAKPOINTS.laptopL}) {
        width: 59%;
    }

    @media screen and (max-width: ${MQ_BREAKPOINTS.tablet}) {
        width: 100%;
    }
`;
