import { FunctionComponent, useEffect } from 'react';
import styled from 'styled-components';

interface Props {
    className?: string;
    isChecked: boolean;
    onChange: (checked: boolean) => void;
    isDisabled?: boolean;
}

const ToggleSwitch: FunctionComponent<Props> = ({ className, isChecked, onChange, isDisabled = false }) => {
    const handleToggle = (): void => {
        if (isDisabled) return;
        onChange(!isChecked);
    };

    return (
        <StyledWrapper
            className={`switch-wrapper ${!isChecked ? 'checked' : ''} ${className ?? ''}`.trim()}
            onClick={handleToggle}
            isDisabled={isDisabled}
        >
            <label className="switch">
                <input
                    type="checkbox"
                    checked={isChecked}
                    // eslint-disable-next-line no-empty-function, @typescript-eslint/no-empty-function
                    onChange={() => {}}
                    disabled={isDisabled}
                />
                <span className="switch-button"></span>
            </label>
        </StyledWrapper>
    );
};

const StyledWrapper = styled.div<{ isDisabled: boolean }>`
    display: flex;
    flex-direction: column;
    cursor: ${({ isDisabled }) => (isDisabled ? 'not-allowed' : 'pointer')};

    .switch {
        position: relative;
        width: 58px;
        height: 22px;
        background-color: var(--purple);
        border-radius: 11px;
        transition: background-color 0.3s ease;

        input {
            opacity: 0;
            width: 100%;
            height: 100%;
            cursor: ${({ isDisabled }) => (isDisabled ? 'not-allowed' : 'pointer')};
        }
    }

    .switch-button {
        position: absolute;
        top: 3px;
        left: 3px;
        width: 16px;
        height: 16px;
        background-color: var(--white);
        border-radius: 50%;
        transition: transform 0.3s ease;

        ${({ isDisabled }) =>
            isDisabled &&
            `
            background-color: var(--red);
        `}
    }

    &.checked {
        .switch {
            background-color: var(--purple);
        }
        .switch-button {
            transform: translateX(36px);
        }
    }
`;

export default ToggleSwitch;
