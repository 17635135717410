import { FunctionComponent, useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { transformDateFormat } from '../../functions/formatDateTime';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisVertical, faCheckSquare, faMinusSquare } from '@fortawesome/free-solid-svg-icons';
import colors from '../../global/colors';
import { formatNumber } from '../../functions/format';
import useTranslations from '../../hooks/useTranslation';
import useLanguageStore from '../../store/language';
import useProfileStore from '../../store/profile';
import { ObjectKeys } from '../../types/objectKeys';
import Dropdown from '../dropdown';
import useDropdown from './useDropdown';
import getStatus from './getStatus';
import getNumberLabel from './getNumberLabel';
import InvoiceActionsWithoutDropdown from './invoiceActionsWithoutDropdown';
import useOutsideClick from '../../hooks/useOutsideClick';
import PaymentDropdown from './paymentDropdown';
import PreviewInvoiceModalPlain from '../../pages/pausal/common/previewInvoiceModalPlain';
import PreviewInvoiceModalArtistic from '../../pages/pausal/common/previewInvoiceModalArtistic';
import PreviewInvoiceModalBusiness from '../../pages/pausal/common/previewInvoiceModalBusiness';
import PreviewInvoiceModalInvoicePro from '../../pages/pausal/common/previewInvoiceModalInvoicePro';
import PreviewInvoiceModalPlain2 from '../../pages/pausal/common/previewInvoiceModalPlain2';
import useUserStore from '../../store/user';
import useAgencyStore from '../../store/agency';
import Modal from '../modal';
import { generateQrCode } from '../../communication/qr';
import { useNavigate } from 'react-router-dom';
import { MQ_BREAKPOINTS } from '../../constants/breakpoints';
import useResponsive from '../../hooks/responsive/useResponsive';

export enum InvoiceCardComponentType {
    Invoice = 'invoice',
    EInvoice = 'eInvoice',
    Proforma = 'proforma',
    AdvanceInvoice = 'advanceInvoice',
    EAdvanceInvoice = 'eAdvanceInvoice',
}
export interface PausalInvoiceCardProps {
    type: string;
    className?: string;
    handleChange?: Function;
    handleDownload?: Function;
    handleCopy?: Function;
    handleCancel?: Function;
    handleDelete?: Function;
    handleSend?: Function;
    handleReminder?: Function;
    handlePayment?: Function;
    handleEInvoice?: Function;
    handleInvoice?: Function;
    handleAdvance?: Function;
    handleClick: Function;
    handleRefresh?: Function;
    cardDisplay: string;
    cardData: ObjectKeys;
    eInvoice?: boolean;
    statusFilter?: ObjectKeys;
    handleUpdatePayment?: Function;
    handleDeletePayment?: Function;
    handleRemovePayments?: Function;
    dropdownDirection?: 'up' | 'down';
}

const InvoiceCard: FunctionComponent<PausalInvoiceCardProps> = ({
    type,
    className,
    handleChange,
    handleCancel,
    handleCopy,
    handleDelete,
    handleDownload,
    handleEInvoice,
    handlePayment,
    handleSend,
    handleReminder,
    handleClick,
    handleInvoice,
    handleAdvance,
    cardDisplay,
    cardData,
    statusFilter,
    eInvoice,
    handleRefresh,
    handleUpdatePayment,
    handleDeletePayment,
    handleRemovePayments,
    dropdownDirection = 'down',
}) => {
    const navigate = useNavigate();
    const role = localStorage.getItem('role');
    const { currentLang, currentLangName } = useLanguageStore();
    const t = useTranslations(currentLang);
    const ref = useRef<HTMLDivElement>(null);
    const { profile } = useProfileStore();
    const { userInfo } = useUserStore();
    const { isLaptopAndDown } = useResponsive();

    const preferredTemplate = userInfo?.preferred_template?.data.display_name;

    const isAccountActive = profile?.active === 1 ? true : false;
    const [dropdownVisible, setDropdownVisible] = useState(false);
    const [showPaymentDropdown, setShowPaymentDropdown] = useState(false);
    const [previewModal, setPreviewModal] = useState(false);
    const { agency } = useAgencyStore();
    const [qrCode, setQrCode] = useState('');

    const { handleClickOutside } = useOutsideClick(ref, setDropdownVisible);

    //QR code
    const handleGenerateQRCode = (
        agencyData: ObjectKeys,
        clientData: ObjectKeys,
        amount: string,
        invoiceNumber: string,
        bankAccount: string,
        currency: string,
        convert: boolean,
    ): void => {
        try {
            generateQrCode(
                agencyData,
                clientData,
                amount,
                invoiceNumber,
                bankAccount,
                currency,
                convert,
                `00${invoiceNumber?.replace('/', '-')}`,
                '221',
            )
                .then((response: ObjectKeys | any) => {
                    if (response.i) setQrCode(response.i);
                })
                .catch((error: any) => {
                    setQrCode('');
                    if (error) {
                        console.error(error);
                    }
                });
        } catch (e: any) {
            setQrCode('');
        }
    };

    const paidDropdown = useDropdown(
        cardData,
        type,
        'paid',
        undefined,
        undefined,
        handleCopy,
        handleDelete,
        handleSend,
        undefined,
        undefined,
        handleDownload,
        handleEInvoice,
        undefined,
        undefined,
        eInvoice ? handleRefresh : undefined,
        eInvoice,
        handleRemovePayments,
    );

    const sentDropdown = useDropdown(
        cardData,
        type,
        'sent',
        handleChange,
        handleCancel,
        handleCopy,
        handleDelete,
        handleSend,
        handleReminder,
        handlePayment,
        handleDownload,
        handleEInvoice,
        undefined,
        undefined,
        eInvoice ? handleRefresh : undefined,
        eInvoice,
        undefined,
    );

    const draftDropdown = useDropdown(
        cardData,
        type,
        'draft',
        handleChange,
        undefined,
        handleCopy,
        handleDelete,
        handleSend,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        eInvoice ? handleRefresh : undefined,
        eInvoice,
        undefined,
    );

    const partiallyDropdown = useDropdown(
        cardData,
        type,
        'partially',
        undefined,
        undefined,
        handleCopy,
        handleDelete,
        handleSend,
        undefined,
        handlePayment,
        handleDownload,
        handleEInvoice,
        undefined,
        undefined,
        eInvoice ? handleRefresh : undefined,
        eInvoice,
        handleRemovePayments,
    );

    const cancelledDropdown = useDropdown(
        cardData,
        type,
        'cancelled',
        handleChange,
        handleCancel,
        handleCopy,
        handleDelete,
        handleSend,
        undefined,
        handlePayment,
        handleDownload,
        handleEInvoice,
        undefined,
        undefined,
        eInvoice ? handleRefresh : undefined,
        eInvoice,
        undefined,
    );

    const proformaDropdown = useDropdown(
        cardData,
        type,
        'proforma',
        handleChange,
        undefined,
        handleCopy,
        handleDelete,
        handleSend,
        undefined,
        undefined,
        handleDownload,
        undefined,
        handleInvoice,
        handleAdvance,
        eInvoice ? handleRefresh : undefined,
        eInvoice,
        undefined,
    );

    const closedDropdown = useDropdown(
        cardData,
        type,
        'closed',
        undefined,
        undefined,
        handleCopy,
        handleDelete,
        handleSend,
        undefined,
        undefined,
        handleDownload,
        handleEInvoice,
        undefined,
        undefined,
        eInvoice ? handleRefresh : undefined,
        eInvoice,
        undefined,
    );

    const adminDropdown = useDropdown(
        cardData,
        type,
        'admin',
        handleChange,
        undefined,
        undefined,
        handleDelete,
        undefined,
        undefined,
        undefined,
        handleDownload,
        undefined,
        undefined,
        undefined,
        eInvoice ? handleRefresh : undefined,
        eInvoice,
        handleRemovePayments,
    );

    // Close Payments dropdown when status filter changes
    useEffect(() => {
        if (statusFilter && statusFilter?.status !== 'paid,partially') {
            setShowPaymentDropdown(false);
        }
    }, [statusFilter]);

    return (
        <StyledInvoiceCard key={cardData.id} className={`card ${className ?? ''} card-display-${cardDisplay}`.trim()}>
            {previewModal && (
                <Modal
                    width="700px"
                    className="preview-modal"
                    modalVisible={true}
                    hasCloseButton={true}
                    closeModal={() => {
                        setPreviewModal(false);
                        setQrCode('');
                    }}
                >
                    {/* //TODO: setQrCode(''); ON CLOSE */}
                    {preferredTemplate === 'Plain' && (
                        <PreviewInvoiceModalPlain
                            agency={agency}
                            invoice={cardData}
                            title={cardData.is_prepaid ? 'advance' : cardData.invoice_number ? 'invoice' : 'proforma'}
                            type={cardData.type === 'national' ? 'domestic' : 'foreign'}
                            note={cardData.comment}
                            qrCode={qrCode}
                        />
                    )}
                    {preferredTemplate === 'Artistic' && (
                        <PreviewInvoiceModalArtistic
                            agency={agency}
                            invoice={cardData}
                            title={cardData.is_prepaid ? 'advance' : cardData.invoice_number ? 'invoice' : 'proforma'}
                            type={cardData.type === 'national' ? 'domestic' : 'foreign'}
                            note={cardData.comment}
                            qrCode={qrCode}
                        />
                    )}
                    {preferredTemplate === 'Business' && (
                        <PreviewInvoiceModalBusiness
                            agency={agency}
                            invoice={cardData}
                            title={cardData.is_prepaid ? 'advance' : cardData.invoice_number ? 'invoice' : 'proforma'}
                            type={cardData.type === 'national' ? 'domestic' : 'foreign'}
                            note={cardData.comment}
                            qrCode={qrCode}
                        />
                    )}
                    {preferredTemplate === 'InvoicePro' && (
                        <PreviewInvoiceModalInvoicePro
                            agency={agency}
                            invoice={cardData}
                            title={cardData.is_prepaid ? 'advance' : cardData.invoice_number ? 'invoice' : 'proforma'}
                            type={cardData.type === 'national' ? 'domestic' : 'foreign'}
                            note={cardData.comment}
                            qrCode={qrCode}
                        />
                    )}
                    {preferredTemplate === 'Plain 2' && (
                        <PreviewInvoiceModalPlain2
                            agency={agency}
                            invoice={cardData}
                            title={cardData.is_prepaid ? 'advance' : cardData.invoice_number ? 'invoice' : 'proforma'}
                            type={cardData.type === 'national' ? 'domestic' : 'foreign'}
                            note={cardData.comment}
                            qrCode={qrCode}
                        />
                    )}
                </Modal>
            )}
            <CardContent className="card-content cursor-pointer">
                <InfoWrapper
                    onClick={() => {
                        if (role !== 'admin') {
                            if (!isAccountActive) {
                                if (cardData.status === 'open') return;
                                handleDownload && handleDownload(cardData.id);
                            } else if (statusFilter?.status === 'paid,partially') {
                                setShowPaymentDropdown(!showPaymentDropdown);
                            } else if (cardData.status === 'open' && cardData.object !== 'Quotation') {
                                if (cardData.is_prepaid) {
                                    navigate(`/advance-invoices/edit/${cardData.id}`);
                                } else {
                                    navigate(`/invoices/edit/${cardData.id}`);
                                }
                            } else {
                                setPreviewModal(true);
                                if (cardData.type !== 'foreign' && !cardData.is_prepaid) {
                                    handleGenerateQRCode(
                                        agency.full_name,
                                        cardData?.client?.data?.company_name,
                                        cardData?.total_for_payment_in_rsd
                                            ? cardData?.total_for_payment_in_rsd
                                            : cardData?.value_in_rsd,
                                        cardData?.invoice_number
                                            ? cardData?.invoice_number
                                            : cardData?.quotation_number,
                                        cardData?.bank_account,
                                        cardData?.currency,
                                        true,
                                    );
                                }
                            }
                        }
                    }}
                    className="info-wrapper"
                >
                    <div className="name-col">
                        <div className="label">{t('pages.invoiceCard.infoWrapper.name').text}</div>
                        <div className="name">
                            {statusFilter?.status === 'paid,partially' ? (
                                <FontAwesomeIcon
                                    icon={cardData.status === 'paid' ? faCheckSquare : faMinusSquare}
                                    style={{
                                        color: cardData.status === 'paid' ? colors.green : colors.danger,
                                        marginRight: '8px',
                                        fontSize: '18px',
                                    }}
                                />
                            ) : (
                                <></>
                            )}
                            {cardData?.client?.data?.company_name}
                        </div>
                    </div>
                    <div className="number-col">
                        <div className="label">{getNumberLabel(currentLangName, type, t)}</div>
                        <div className="number">
                            {(type === 'proforma' && cardData?.quotation_number) ||
                                (cardData?.invoice_number
                                    ? cardData?.invoice_number
                                    : getStatus(cardData?.status?.toLowerCase(), t))}
                        </div>
                    </div>
                    <div className="date-col">
                        <div className="label">{t('pages.invoiceCard.infoWrapper.date').text}</div>
                        <div className="date">
                            {transformDateFormat(
                                type === 'proforma' ? cardData?.quotation_date : cardData?.invoice_date,
                            )}
                        </div>
                    </div>
                    <div className="amount-col">
                        <div className="label">{t('pages.invoiceCard.infoWrapper.amount').text}</div>
                        <div className="amount">
                            {formatNumber(cardData?.value, 2, 3, '.', ',') + ' ' + cardData?.currency}
                        </div>
                    </div>
                    {type !== 'proforma' && (
                        <>
                            <div className="status-col">
                                <div className="label">{t('pages.invoiceCard.infoWrapper.status').text}</div>
                                <div className={`status ${cardData?.status}`}>
                                    <span>{getStatus(cardData?.status?.toLowerCase(), t)}</span>
                                </div>
                            </div>
                            <>
                                {cardData.is_einvoice === 1 ? (
                                    <div className="status-col">
                                        <div className="label">{t('pages.eInvoices.status.title').text}</div>
                                        <div className={`status ${cardData?.eInvoice?.data?.status?.toLowerCase()}`}>
                                            <span>
                                                {getStatus(
                                                    cardData?.eInvoice?.data?.status?.toLowerCase(),
                                                    t,
                                                    cardData?.eInvoice,
                                                )}
                                            </span>
                                        </div>
                                    </div>
                                ) : null}
                            </>
                        </>
                    )}
                </InfoWrapper>
                {isAccountActive ? (
                    <ActionWrapper>
                        <div className="label"></div>
                        <div className="action">
                            {isLaptopAndDown ? (
                                <Action>
                                    <FontAwesomeIcon
                                        icon={faEllipsisVertical}
                                        style={{ color: colors.gray }}
                                        className="cursor-pointer dots"
                                        onClick={() => {
                                            handleClick(cardData?.id);
                                            setDropdownVisible(!dropdownVisible);
                                        }}
                                    />
                                    {dropdownVisible && (
                                        <DropdownContainer
                                            ref={ref}
                                            className={dropdownDirection}
                                            onClick={() => {
                                                handleClickOutside;
                                                setDropdownVisible(false);
                                            }}
                                        >
                                            <Dropdown
                                                arrowRight="4px"
                                                caretDown={dropdownDirection === 'up'}
                                                itemList={
                                                    role !== 'admin'
                                                        ? cardData?.status === 'paid'
                                                            ? paidDropdown
                                                            : cardData?.status === 'sent'
                                                            ? sentDropdown
                                                            : cardData?.status === 'open' && type !== 'proforma'
                                                            ? draftDropdown
                                                            : cardData?.status === 'partially'
                                                            ? partiallyDropdown
                                                            : cardData?.status === 'cancelled'
                                                            ? cancelledDropdown
                                                            : cardData?.status === 'open' && type === 'proforma'
                                                            ? proformaDropdown
                                                            : cardData?.status === 'closed'
                                                            ? closedDropdown
                                                            : []
                                                        : adminDropdown
                                                }
                                            />
                                        </DropdownContainer>
                                    )}
                                </Action>
                            ) : (
                                <InvoiceActionsWithoutDropdown
                                    cardData={cardData}
                                    handleDownload={handleDownload}
                                    handleInvoice={handleInvoice}
                                    handleAdvance={handleAdvance}
                                    handleCopy={handleCopy}
                                    handlePayment={handlePayment}
                                    handleCancel={handleCancel}
                                    handleChange={handleChange}
                                    handleDelete={handleDelete}
                                    handleSend={handleSend}
                                    handleReminder={handleReminder}
                                    handleEInvoice={handleEInvoice}
                                    handleRefresh={handleRefresh}
                                    handleRemovePayments={handleRemovePayments}
                                    type={type}
                                    t={t}
                                    eInvoice={eInvoice}
                                />
                            )}
                        </div>
                    </ActionWrapper>
                ) : (
                    <></>
                )}
                {role === 'admin' && !isAccountActive ? (
                    <ActionWrapper>
                        <div className="label"></div>
                        <div className="action">
                            {isLaptopAndDown ? (
                                <Action>
                                    <FontAwesomeIcon
                                        icon={faEllipsisVertical}
                                        style={{ color: colors.gray }}
                                        className="cursor-pointer dots"
                                        onClick={() => {
                                            handleClick(cardData?.id);
                                            setDropdownVisible(!dropdownVisible);
                                        }}
                                    />
                                    {dropdownVisible && (
                                        <DropdownContainer
                                            ref={ref}
                                            onClick={handleClickOutside}
                                            className={dropdownDirection}
                                        >
                                            <Dropdown
                                                arrowRight="4px"
                                                caretDown={dropdownDirection === 'up'}
                                                itemList={
                                                    role !== 'admin'
                                                        ? cardData?.status === 'paid'
                                                            ? paidDropdown
                                                            : cardData?.status === 'sent'
                                                            ? sentDropdown
                                                            : cardData?.status === 'open' && type !== 'proforma'
                                                            ? draftDropdown
                                                            : cardData?.status === 'partially'
                                                            ? partiallyDropdown
                                                            : cardData?.status === 'cancelled'
                                                            ? cancelledDropdown
                                                            : cardData?.status === 'open' && type === 'proforma'
                                                            ? proformaDropdown
                                                            : cardData?.status === 'closed'
                                                            ? closedDropdown
                                                            : []
                                                        : adminDropdown
                                                }
                                            />
                                        </DropdownContainer>
                                    )}
                                </Action>
                            ) : (
                                <InvoiceActionsWithoutDropdown
                                    cardData={cardData}
                                    handleDownload={handleDownload}
                                    handleInvoice={handleInvoice}
                                    handleAdvance={handleAdvance}
                                    handleCopy={handleCopy}
                                    handlePayment={handlePayment}
                                    handleCancel={handleCancel}
                                    handleChange={handleChange}
                                    handleDelete={handleDelete}
                                    handleSend={handleSend}
                                    handleEInvoice={handleEInvoice}
                                    type={type}
                                    t={t}
                                    eInvoice={eInvoice}
                                />
                            )}
                        </div>
                    </ActionWrapper>
                ) : (
                    <></>
                )}
            </CardContent>
            {showPaymentDropdown && !cardData?.cancelled ? (
                cardData?.payments?.data ? (
                    <PaymentDropdown
                        cardData={cardData}
                        t={t}
                        handlePayment={handlePayment}
                        type={type}
                        handleUpdatePayment={handleUpdatePayment}
                        handleDeletePayment={handleDeletePayment}
                    />
                ) : (
                    <></>
                )
            ) : (
                <></>
            )}
        </StyledInvoiceCard>
    );
};

const StyledInvoiceCard = styled.div`
    margin-bottom: 10px;
    padding: 16px 20px;
    border-radius: 5px;
    width: 100%;

    background: var(--white);
    border: 1px solid var(--purple);
    transition: all 0.5s ease-in-out;
    &:hover {
        box-shadow: 0 0 5px 5px #eee;
        .info-wrapper {
            opacity: 0.7;
        }
    }
    @media screen and (max-width: ${MQ_BREAKPOINTS.tablet}) {
        align-items: start;
    }
    &.card-display-grid {
        display: flex;
        flex-direction: column;
        .card-content {
            grid-template-columns: 1fr;
            width: 100%;
            .info-wrapper {
                grid-template-columns: 2fr 2fr;
                > div {
                    &:nth-of-type(1) {
                        order: 3;
                        grid-column: 1 / 4;
                    }
                    &:nth-of-type(2) {
                        order: 2;
                        margin-bottom: 20px;
                        display: flex;
                        flex-direction: column;
                        align-items: flex-end;
                    }
                    &:nth-of-type(3) {
                        order: 4;
                        grid-column: 1 / 4;
                    }
                    &:nth-of-type(4) {
                        order: 4;
                    }
                    &.status-col {
                        order: 1;
                        margin-bottom: 20px;
                    }
                }
            }
        }
    }
`;

const CardContent = styled.div`
    display: grid;
    align-items: center;
    grid-template-columns: 3fr 1fr;
    @media screen and (max-width: ${MQ_BREAKPOINTS.laptopL}) {
        grid-template-columns: 8fr 1fr;
    }

    div {
        color: var(--black);
        &.number {
            text-transform: capitalize;
        }
        &.status {
            text-transform: capitalize;
            &.sent {
                span {
                    background: var(--blue);
                }
            }
            &.paid {
                span {
                    background: var(--green);
                }
            }
            &.cancelled {
                span {
                    background: var(--danger);
                }
            }
            &.partially {
                span {
                    background: linear-gradient(90deg, var(--green), var(--blue));
                }
            }
            &.approved {
                span {
                    background: var(--green);
                }
            }
            &.storno {
                span {
                    background: var(--danger);
                }
            }
            &.rejected {
                span {
                    background: var(--danger);
                }
            }
            span {
                padding: 0 10px 2px;
                background: var(--gray);
                color: var(--white);
                border-radius: 50px;
                font-size: 12px;
            }
        }
        &.action {
            display: flex;
            justify-content: flex-end;
        }
    }
`;

const InfoWrapper = styled.div`
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    gap: 1rem;
    display: grid;
    align-items: center;
    @media screen and (max-width: ${MQ_BREAKPOINTS.tablet}) {
        grid-template-columns: 2fr 2fr;
    }

    .label {
        font-size: 12px;
        color: var(--gray);
        line-height: 25px;
        &::first-letter {
            text-transform: capitalize;
        }
    }
    .amount-col {
        .amount {
            overflow-wrap: break-word;
        }
    }
    .name-col {
        grid-column: span 2;
    }
`;

const ActionWrapper = styled.div`
    display: flex;
    justify-content: flex-end;

    div {
        &.action {
            justify-content: center;
            margin-top: 20px;
        }
    }
`;

const Action = styled.div`
    position: relative;
    .dots {
        font-size: 25px;
        padding: 10px 17px 7px;
        transition: all 0.5s ease-in-out;
        &:hover {
            background: var(--light-gray);
        }
    }
`;

const DropdownContainer = styled.div`
    position: absolute;
    z-index: 1;
    right: 0;
    &.down {
        top: 55px;
    }
    &.up {
        bottom: 55px;
    }
    p {
        font-size: 16px;
        line-height: 20px;
    }
    .dropdown-container {
        svg {
            height: 20px;
            width: 20px;
        }
    }
`;

export default InvoiceCard;
