import React, { ReactNode } from 'react';
import styled from 'styled-components';
import Doughnut from './doughnut';
import BarChart from './barChart';
import { MQ_BREAKPOINTS } from '../../../../constants/breakpoints';

interface SplitScreenProps {
    leftComponent: ReactNode;
    rightComponent: ReactNode;
}

const SplitScreen: React.FC<SplitScreenProps> = ({ leftComponent, rightComponent }) => {
    return (
        <>
            <SplitScreenContainer>
                <LeftHalf>{leftComponent}</LeftHalf>
                <RightHalf>{rightComponent}</RightHalf>
            </SplitScreenContainer>
        </>
    );
};

const Charts: React.FC = () => {
    return <SplitScreen leftComponent={<Doughnut />} rightComponent={<BarChart />} />;
};
const SplitScreenContainer = styled.div`
    display: flex;
    flex-wrap: wrap;

    @media only screen and (max-width: ${MQ_BREAKPOINTS.tablet}) {
        flex-direction: column;
        flex-wrap: nowrap;
    }
`;

const LeftHalf = styled.div`
    flex: 1;
    border-right: 1px solid #ccc;
    padding: 20px;
    @media screen and (max-width: ${MQ_BREAKPOINTS.tablet}) {
        padding: 20px 0;
        border-right: none;
    }
`;

const RightHalf = styled.div`
    flex: 1;
    padding: 20px;
    @media screen and (max-width: ${MQ_BREAKPOINTS.tablet}) {
        padding: 20px 0;
    }
`;

export default Charts;
