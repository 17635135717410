type Instructions = {
    id: number;
    title: string;
    link_rs: string;
    link_en: string;
    link_ru: string;
};

const getInstructions = (t: any): Instructions[] => [
    {
        id: 1,
        title: t('pages.instructions.1').text,
        link_rs: 'https://pausal.rs/pdf/uputstva/rs/Pausal-Domaca-Faktura-SRB.pdf',
        link_en: 'https://pausal.rs/pdf/uputstva/eng/Pausal-Domaca-Faktura-ENG.pdf',
        link_ru: 'https://pausal.rs/pdf/uputstva/ru/Pausal-Domaca-Faktura-RUS.pdf',
    },
    {
        id: 2,
        title: t('pages.instructions.2').text,
        link_rs: 'https://pausal.rs/pdf/uputstva/rs/Pausal-Ino-Faktura-SRB.pdf',
        link_en: 'https://pausal.rs/pdf/uputstva/eng/Pausal-Ino-Faktura-ENG.pdf',
        link_ru: 'https://pausal.rs/pdf/uputstva/ru/Pausal-Ino-Faktura-RUS.pdf',
    },
    {
        id: 3,
        title: t('pages.instructions.3').text,
        link_rs: 'https://pausal.rs/pdf/uputstva/rs/Pausal-eFaktura-SRB.pdf',
        link_en: 'https://pausal.rs/pdf/uputstva/eng/Pausal-eFaktura-ENG.pdf',
        link_ru: 'https://pausal.rs/pdf/uputstva/ru/Pausal-eFaktura-RUS.pdf',
    },
    {
        id: 4,
        title: t('pages.instructions.4').text,
        link_rs: 'https://pausal.rs/pdf/uputstva/rs/Pausal-Kalendar-Poreza-I-Doprinosa-SRB.pdf',
        link_en: 'https://pausal.rs/pdf/uputstva/eng/Pausal-Kalendar-Poreza-I-Doprinosa-ENG.pdf',
        link_ru: 'https://pausal.rs/pdf/uputstva/ru/Pausal-Kalendar-Poreza-I-Doprinosa-RUS.pdf',
    },
    {
        id: 5,
        title: t('pages.instructions.5').text,
        link_rs: 'https://pausal.rs/pdf/uputstva/rs/Pausal-KPO-Knjiga-SRB.pdf',
        link_en: 'https://pausal.rs/pdf/uputstva/eng/Pausal-KPO-knjiga-ENG.pdf',
        link_ru: 'https://pausal.rs/pdf/uputstva/ru/Pausal-KPO-knjiga-RUS.pdf',
    },
    {
        id: 6,
        title: t('pages.instructions.6').text,
        link_rs: 'https://pausal.rs/pdf/uputstva/rs/Pausal-Limiti-SRB.pdf',
        link_en: 'https://pausal.rs/pdf/uputstva/eng/Pausal-Limiti-ENG.pdf',
        link_ru: 'https://pausal.rs/pdf/uputstva/ru/Pausal-Limiti-RUS.pdf',
    },
    {
        id: 7,
        title: t('pages.instructions.7').text,
        link_rs: 'https://pausal.rs/pdf/uputstva/rs/Pausal-Spisak-Komitenata-SRB.pdf',
        link_en: 'https://pausal.rs/pdf/uputstva/eng/Pausal-Spisak-Komitenata-ENG.pdf',
        link_ru: 'https://pausal.rs/pdf/uputstva/ru/Pausal-Spisak-Komitenata-RUS.pdf',
    },
    {
        id: 8,
        title: t('pages.instructions.8').text,
        link_rs: 'https://pausal.rs/pdf/uputstva/rs/Pausal-Statistika-SRB.pdf',
        link_en: 'https://pausal.rs/pdf/uputstva/eng/Pausal-Statistika-ENG.pdf',
        link_ru: 'https://pausal.rs/pdf/uputstva/ru/Pausal-Statistika-RUS.pdf',
    },
    {
        id: 9,
        title: t('pages.instructions.9').text,
        link_rs: 'https://pausal.rs/pdf/uputstva/rs/Pausal-Prenos-Ovlascenja-SRB.pdf',
        link_en: 'https://pausal.rs/pdf/uputstva/eng/Pausal-Prenos-Ovlascenja-ENG.pdf',
        link_ru: 'https://pausal.rs/pdf/uputstva/ru/Pausal-Prenos-Ovlascenja-RUS.pdf',
    },
    {
        id: 10,
        title: t('pages.instructions.10').text,
        link_rs: 'https://pausal.rs/pdf/uputstva/rs/Pausal-Dodaj-Logo-SRB.pdf',
        link_en: 'https://pausal.rs/pdf/uputstva/eng/Pausal-Dodaj-Logo-ENG.pdf',
        link_ru: 'https://pausal.rs/pdf/uputstva/ru/Pausal-Dodaj-Logo-RUS.pdf',
    },
    {
        id: 11,
        title: t('pages.instructions.11').text,
        link_rs: 'https://pausal.rs/pdf/uputstva/rs/Pausal-Template-Faktura-SRB.pdf',
        link_en: 'https://pausal.rs/pdf/uputstva/eng/Pausal-Template-Faktura-ENG.pdf',
        link_ru: 'https://pausal.rs/pdf/uputstva/ru/Pausal-Template-Faktura-RUS.pdf',
    },
    {
        id: 12,
        title: t('pages.instructions.12').text,
        link_rs: 'https://pausal.rs/pdf/uputstva/rs/Pausal-Bankovni-Racuni-PDF.pdf',
        link_en: 'https://pausal.rs/pdf/uputstva/eng/Pausal-Bankovni-Racuni-ENG.pdf',
        link_ru: 'https://pausal.rs/pdf/uputstva/ru/Pausal-Bankovni-Racuni-RUS.pdf',
    },
    {
        id: 13,
        title: t('pages.instructions.13').text,
        link_rs: 'https://pausal.rs/pdf/uputstva/rs/Pausal-Pretplata-SRB.pdf',
        link_en: 'https://pausal.rs/pdf/uputstva/eng/Pausal-Pretplata-ENG.pdf',
        link_ru: 'https://pausal.rs/pdf/uputstva/ru/Pausal-Pretplata-RUS.pdf',
    },
    {
        id: 14,
        title: t('pages.instructions.14').text,
        link_rs: 'https://pausal.rs/pdf/uputstva/rs/Pausal-Spisak-Artikala-SRB.pdf',
        link_en: 'https://pausal.rs/pdf/uputstva/eng/Pausal-Spisak-Artikala-ENG.pdf',
        link_ru: 'https://pausal.rs/pdf/uputstva/ru/Pausal-Spisak-artikala-RUS.pdf',
    },
    {
        id: 15,
        title: t('pages.instructions.15').text,
        link_rs: 'https://pausal.rs/pdf/uputstva/rs/Pausal-Rabat-SRB.pdf',
        link_en: 'https://pausal.rs/pdf/uputstva/eng/Pausal-Rabat-ENG.pdf',
        link_ru: 'https://pausal.rs/pdf/uputstva/ru/Pausal-Rabat-RUS.pdf',
    },
    {
        id: 16,
        title: t('pages.instructions.16').text,
        link_rs: 'https://pausal.rs/pdf/uputstva/rs/Pausal-Usluge-SRB.pdf',
        link_en: 'https://pausal.rs/pdf/uputstva/eng/Pausal-Usluge-ENG.pdf',
        link_ru: 'https://pausal.rs/pdf/uputstva/ru/Pausal-Usluge-RUS.pdf',
    },
    {
        id: 17,
        title: t('pages.instructions.17').text,
        link_rs: 'https://pausal.rs/pdf/uputstva/rs/Pausal-Meni-SRB.pdf',
        link_en: 'https://pausal.rs/pdf/uputstva/eng/Pausal-Meni-ENG.pdf',
        link_ru: 'https://pausal.rs/pdf/uputstva/ru/Pausal-Meni-RUS.pdf',
    },
    {
        id: 18,
        title: t('pages.instructions.18').text,
        link_rs: 'https://pausal.rs/pdf/uputstva/rs/Pausal-Ostali-Nalozi-SRB.pdf',
        link_en: 'https://pausal.rs/pdf/uputstva/eng/Pausal-Ostali-Nalozi-ENG.pdf',
        link_ru: 'https://pausal.rs/pdf/uputstva/ru/Pausal-Ostali-Nalozi-RUS.pdf',
    },
    {
        id: 19,
        title: t('pages.instructions.19').text,
        link_rs: 'https://pausal.rs/pdf/uputstva/rs/Pausal-Srednji-Kurs-NBS-SRB.pdf',
        link_en: 'https://pausal.rs/pdf/uputstva/eng/Pausal-Srednji-Kurs-NBS-ENG.pdf',
        link_ru: 'https://pausal.rs/pdf/uputstva/ru/Pausal-Srednji-Kurs-NBS-RUS.pdf',
    },
    {
        id: 20,
        title: t('pages.instructions.20').text,
        link_rs: 'https://pausal.rs/pdf/uputstva/rs/Pausal-Domaca-Profaktura-SRB.pdf',
        link_en: 'https://pausal.rs/pdf/uputstva/eng/Pausal-Domaca-Profaktura-ENG.pdf',
        link_ru: 'https://pausal.rs/pdf/uputstva/ru/Pausal-Domaca-Profaktura-RUS.pdf',
    },
    {
        id: 21,
        title: t('pages.instructions.21').text,
        link_rs: 'https://pausal.rs/pdf/uputstva/rs/Pausal-Ino-Profaktura-SRB.pdf',
        link_en: 'https://pausal.rs/pdf/uputstva/eng/Pausal-Ino-Profaktura-ENG.pdf',
        link_ru: 'https://pausal.rs/pdf/uputstva/ru/Pausal-Ino-Profaktura-RUS.pdf',
    },
    {
        id: 22,
        title: t('pages.instructions.22').text,
        link_rs: 'https://pausal.rs/pdf/uputstva/rs/Pausal-Avansna-Faktura-SRB.pdf',
        link_en: 'https://pausal.rs/pdf/uputstva/eng/Pausal-Avansna-Faktura-ENG.pdf',
        link_ru: 'https://pausal.rs/pdf/uputstva/ru/Pausal-Avansna-Faktura-RUS.pdf',
    },
    {
        id: 23,
        title: t('pages.instructions.23').text,
        link_rs: 'https://pausal.rs/pdf/uputstva/rs/Pausal-Ino-Avansna-Faktura-SRB.pdf',
        link_en: 'https://pausal.rs/pdf/uputstva/eng/Pausal-Ino-Avansna-Faktura-ENG.pdf',
        link_ru: 'https://pausal.rs/pdf/uputstva/ru/Pausal-Ino-Avansna-Faktura-RUS.pdf',
    },
    {
        id: 24,
        title: t('pages.instructions.24').text,
        link_rs: 'https://pausal.rs/pdf/uputstva/rs/Pausal-eAvans-SRB.pdf',
        link_en: 'https://pausal.rs/pdf/uputstva/eng/Pausal-eAvans-ENG.pdf',
        link_ru: 'https://pausal.rs/pdf/uputstva/ru/Pausal-eAvans-RUS.pdf',
    },
    {
        id: 25,
        title: t('pages.instructions.25').text,
        link_rs: 'https://pausal.rs/pdf/uputstva/rs/Pausal-Memorandumi-SRB.pdf',
        link_en: 'https://pausal.rs/pdf/uputstva/eng/Pausal-Memorandumi-ENG.pdf',
        link_ru: 'https://pausal.rs/pdf/uputstva/ru/Pausal-Memorandumi-RUS.pdf',
    },
    {
        id: 26,
        title: t('pages.instructions.26').text,
        link_rs: 'https://pausal.rs/pdf/uputstva/rs/Pausal-Sugestije-SRB.pdf',
        link_en: 'https://pausal.rs/pdf/uputstva/eng/Pausal-Sugestije-ENG.pdf',
        link_ru: 'https://pausal.rs/pdf/uputstva/ru/Pausal-Sugestije-RUS.pdf',
    },
    {
        id: 27,
        title: t('pages.instructions.27').text,
        link_rs: 'https://pausal.rs/pdf/uputstva/rs/Pausal-Notifikacije-SRB.pdf',
        link_en: 'https://pausal.rs/pdf/uputstva/eng/Pausal-Notifikacije-ENG.pdf',
        link_ru: 'https://pausal.rs/pdf/uputstva/ru/Pausal-Notifikacije-RUS.pdf',
    },
    {
        id: 28,
        title: t('pages.instructions.28').text,
        link_rs: 'https://pausal.rs/pdf/uputstva/rs/Pausal-Cet-SRB.pdf',
        link_en: 'https://pausal.rs/pdf/uputstva/eng/Pausal-Cet-ENG.pdf',
        link_ru: 'https://pausal.rs/pdf/uputstva/ru/Pausal-Cet-RUS.pdf',
    },
    {
        id: 29,
        title: t('pages.instructions.29').text,
        link_rs: 'https://pausal.rs/pdf/uputstva/rs/Pausal-Promena-Lozinke-SRB.pdf',
        link_en: 'https://pausal.rs/pdf/uputstva/eng/Pausal-Promena-Lozinke-ENG.pdf',
        link_ru: 'https://pausal.rs/pdf/uputstva/ru/Pausal-Promena-Lozinke-RUS.pdf',
    },
];

export default getInstructions;
