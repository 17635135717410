import { FunctionComponent, useEffect, useState } from 'react';
import styled from 'styled-components';
import useLanguageStore from '../../../store/language';
import useTranslations from '../../../hooks/useTranslation';
import Button from '../../../components/button';
import colors from '../../../global/colors';
import { ButtonVariant } from '../../../components/button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faPrint } from '@fortawesome/free-solid-svg-icons';
import Card from './card';
import communication from '../../../communication';
import Loader from '../../../components/loader';
import { CreateHealthCardsType, HealthCardsType } from '../../../types/communication/healthCards';
import NoItems from '../../../components/noItems';
import Sidebar from '../../../components/sideModal';
import NewHealthCardModal from './newCardModal';
import Modal from '../../../components/modal';
import ChangeModal from '../../../components/changeModal';
import Success from '../../../components/successModal';
import useAgencyStore from '../../../store/agency';
import ConfirmModal from '../../../components/confirmModal';
import { MQ_BREAKPOINTS } from '../../../constants/breakpoints';

const HealthCards: FunctionComponent = () => {
    const { currentLang, currentLangName } = useLanguageStore();
    const t = useTranslations(currentLang);
    const { agency } = useAgencyStore();

    const [formData, setFormData] = useState<HealthCardsType[]>();
    const [changeHappened, setChangeHappened] = useState(false);
    const [formHasBeenEdited, setFormHasBeenEdited] = useState(false);
    const [formHasBeenDirty, setFormHasBeenDirty] = useState(false);
    const [newCardModal, setNewCardModal] = useState(false);
    const [editCardModal, setEditCardModal] = useState(false);
    const [loaderVisible, setLoaderVisible] = useState(false);
    const [changeModal, setChangeModal] = useState(false);
    const [successModal, setSuccessModal] = useState(false);
    const [isUnsavedChangesModalOpen, setIsUnsavedChangesModalOpen] = useState(false);
    const [editUser, setEditUser] = useState(false);
    const [modalMessage, setModalMessage] = useState('');
    const [cardData, setCardData] = useState<CreateHealthCardsType>({
        first_name: '',
        last_name: '',
        parent_name: '',
        jmbg: '',
        street_address: '',
        street_number: '',
        city: '',
        education: '',
        municipality_id: '',
        citizenship: 'Srpsko',
        gender: '',
        start_date: '',
    });
    const [cardForEdit, setCardForEdit] = useState<HealthCardsType>();

    const user = agency.user.data;

    const getHealthCards = async (): Promise<void> => {
        const res = await communication.getHealthCards();
        const data: HealthCardsType[] = res.data.data;
        setFormData(data);
    };

    useEffect(() => {
        getHealthCards();
    }, [changeHappened, newCardModal, changeModal, successModal]);

    const insuranceCarrier = formData?.find((x) => x.insurance_type === 'insurance_carrier');

    const createCarrierCard = async (healthCard: CreateHealthCardsType): Promise<void> => {
        setLoaderVisible(true);
        const res = await communication.createHealthCard(healthCard);
        if (res.status === 200) {
            setLoaderVisible(false);
        }
        setChangeHappened(!changeHappened);
    };

    const handleUpdate = async (): Promise<void> => {
        setChangeModal(false);
        createCarrierCard(cardData).then(() => {
            communication.updateUser(
                {
                    first_name: cardData.first_name,
                    last_name: cardData.last_name,
                    middle_name: cardData.parent_name,
                    jmbg: cardData.jmbg,
                    education: cardData.education,
                    gender: cardData.gender,
                    city: cardData.city,
                    municipality_id: cardData.municipality_id,
                    address: cardData.street_address,
                    street_number: cardData.street_number,
                    citizenship: cardData.citizenship,
                    country_id: cardData.country_id,
                },
                user?.id,
            );
            setChangeHappened(!changeHappened);
        });
    };

    const updateUser = async (): Promise<void> => {
        setChangeModal(false);
        const res = await communication.updateHealthCard(cardData.id ? cardData.id : '', cardData);
        if (res.status === 200) {
            await communication.updateUser(
                Object.assign({}, user, {
                    first_name: cardData.first_name,
                    last_name: cardData.last_name,
                    middle_name: cardData.parent_name,
                    jmbg: cardData.jmbg,
                    education: cardData.education,
                    gender: cardData.gender,
                    city: cardData.city,
                    municipality_id: cardData.municipality_id,
                    address: cardData.street_address,
                    street_number: cardData.street_number,
                    citizenship: cardData.citizenship,
                }),
                user?.id,
            );
            setModalMessage(t('pages.healthCards.updateSuccess').text);
            setSuccessModal(true);
        }
        setChangeHappened(!changeHappened);
    };

    const handleEditCardModalClose = (validate?: boolean) => {
        if (formHasBeenEdited && validate) {
            setIsUnsavedChangesModalOpen(true);
        } else {
            setEditCardModal(false);
        }
    };

    const handleNewCardModalClose = (validate?: boolean) => {
        if (formHasBeenDirty && validate) {
            setIsUnsavedChangesModalOpen(true);
        } else {
            setNewCardModal(false);
        }
    };

    return (
        <>
            {loaderVisible && <Loader />}
            {changeModal && (
                <Modal
                    modalVisible={true}
                    closeModal={() => {
                        setChangeModal(false);
                    }}
                >
                    <ChangeModal
                        saveChanges={editUser ? updateUser : handleUpdate}
                        close={() => {
                            setChangeModal(false);
                        }}
                        message={t('pages.healthCards.newCardModal.changeData').text}
                    />
                </Modal>
            )}
            {newCardModal && (
                <>
                    <Sidebar close={() => handleNewCardModalClose(true)}>
                        <NewHealthCardModal
                            loading={() => setLoaderVisible(true)}
                            finishLoading={() => setLoaderVisible(false)}
                            cardsLength={insuranceCarrier ? 1 : 0}
                            close={(validate: boolean) => handleNewCardModalClose(validate)}
                            t={t}
                            changeModal={(res: CreateHealthCardsType) => {
                                setCardData(res);
                                setChangeModal(true);
                            }}
                            currentLang={currentLang}
                            setFormHasBeenEdited={() => void {}}
                            setFormHasDirtyData={(sign: boolean) => {
                                setFormHasBeenDirty(sign);
                            }}
                        />
                    </Sidebar>
                </>
            )}
            {editCardModal && (
                <Sidebar close={() => handleEditCardModalClose(true)}>
                    <NewHealthCardModal
                        edit
                        loading={() => setLoaderVisible(true)}
                        finishLoading={() => setLoaderVisible(false)}
                        cardsLength={cardForEdit?.insurance_type === 'insurance_carrier' ? 0 : 1}
                        close={(validate: boolean) => handleEditCardModalClose(validate)}
                        t={t}
                        changeModal={async (res: CreateHealthCardsType) => {
                            if (cardForEdit?.insurance_type === 'insurance_carrier') {
                                setCardData(res);
                                setEditUser(true);
                                setChangeModal(true);
                            } else {
                                await communication.updateHealthCard(res.id ? res.id : '', res);
                                setChangeHappened(!changeHappened);
                            }
                        }}
                        editData={cardForEdit}
                        success={() => {
                            setSuccessModal(true);
                            setModalMessage(t('pages.healthCards.updateSuccess').text);
                        }}
                        currentLang={currentLangName}
                        setFormHasBeenEdited={setFormHasBeenEdited}
                        setFormHasDirtyData={() => void {}}
                    />
                </Sidebar>
            )}
            {successModal && (
                <Modal modalVisible={true} closeModal={() => setSuccessModal(false)}>
                    <Success close={() => setSuccessModal(false)} message={modalMessage} />
                </Modal>
            )}
            {isUnsavedChangesModalOpen && (
                <Modal modalVisible={isUnsavedChangesModalOpen} closeModal={() => setIsUnsavedChangesModalOpen(false)}>
                    <ConfirmModal
                        message={t('warnings.youHaveUnsavedChanges').text}
                        close={() => setIsUnsavedChangesModalOpen(false)}
                        action={() => {
                            setIsUnsavedChangesModalOpen(false);
                            setEditCardModal(false);
                            setNewCardModal(false);
                        }}
                    />
                </Modal>
            )}
            {formData ? (
                <Container>
                    <Header>
                        <h1>{t('pages.healthCards.title').text}</h1>
                        <div className="buttons">
                            <Button
                                size={'max-content'}
                                onClick={() => setNewCardModal(true)}
                                variant={ButtonVariant.solid}
                                color={colors.purple}
                                icon
                            >
                                <FontAwesomeIcon icon={faPlus} />
                                {t('pages.healthCards.new').text}
                            </Button>
                            <a href="/mobrazac.pdf" download>
                                <Button
                                    size={'max-content'}
                                    variant={ButtonVariant.outlined}
                                    color={colors.purple}
                                    icon
                                >
                                    <FontAwesomeIcon icon={faPrint} />
                                    {t('pages.healthCards.blanko').text}
                                </Button>
                            </a>
                        </div>
                    </Header>
                    <PurpleHeader>
                        <div>
                            <p>{t('pages.healthCards.list').text}</p>
                        </div>
                    </PurpleHeader>
                    {formData.length > 0 ? (
                        <div className="cards">
                            {formData.map((card) => (
                                <CardContainer key={card.id}>
                                    <Card
                                        edit={(data: HealthCardsType) => {
                                            setCardForEdit(data);
                                            setEditCardModal(true);
                                        }}
                                        changed={() => setChangeHappened(!changeHappened)}
                                        data={card}
                                    />
                                </CardContainer>
                            ))}
                        </div>
                    ) : (
                        <NoItems text={t('pages.healthCards.newCardModal.subtitle').text} />
                    )}
                </Container>
            ) : (
                <Loader />
            )}
        </>
    );
};
export default HealthCards;

const Container = styled.div`
    vertical-align: top;
    display: flex;
    flex-direction: column;
    .cards {
        margin-top: 25px;
        margin-bottom: 150px;
    }

    @media screen and (max-width: ${MQ_BREAKPOINTS.tablet}) {
        width: 100%;
    }
`;
const PurpleHeader = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    align-items: center;
    background-color: var(--purple);
    color: white;
    padding: 22px;
    font-size: 14px;
    @media only screen and (max-width: ${MQ_BREAKPOINTS.mobileL}) {
        justify-content: space-between;
    }
    p {
        font-size: 14px;
        font-weight: 400;
        text-transform: uppercase;
    }
`;
const Header = styled.div`
    border-bottom: 1px solid var(--border-color);
    padding-bottom: 20px;
    margin-bottom: 20px;
    overflow: hidden;
    display: grid;
    grid-template-columns: auto auto;
    @media screen and (max-width: ${MQ_BREAKPOINTS.tablet}) {
        display: flex;
        flex-direction: column;
    }
    h1 {
        font-size: 36px;
        color: var(--navbar-gray);
        font-weight: 400;
        @media screen and (max-width: ${MQ_BREAKPOINTS.mobileL}) {
            font-size: 25px;
        }
    }
    .buttons {
        float: right;
        display: flex;
        justify-content: flex-end;
        a {
            margin-right: -35px;
        }
        button {
            height: 35px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 12px;
            :first-child {
                margin-right: 35px;
                @media screen and (max-width: ${MQ_BREAKPOINTS.tablet}) {
                    margin-right: 10px;
                    margin-bottom: 10px;
                }
            }
        }
        @media screen and (max-width: ${MQ_BREAKPOINTS.tablet}) {
            justify-content: unset;
            margin-top: 20px;
            display: flex;
            flex-direction: column;
        }
    }
`;
const CardContainer = styled.div`
    box-sizing: border-box;
    width: 33%;
    float: left;
    padding: 15px;
    vertical-align: top;
    margin-right: -4px;
    @media screen and (max-width: ${MQ_BREAKPOINTS.laptopL}) {
        width: 50%;
    }
    @media screen and (max-width: ${MQ_BREAKPOINTS.tablet}) {
        width: 100%;
    }
`;
