import { useMediaQuery } from 'react-responsive';
import { breakpoints } from '../../constants/breakpoints';

const useResponsive = () => {
    const isMobile = useMediaQuery({ query: `(max-width: ${breakpoints.tablet}px)` });
    const isTablet = useMediaQuery({
        query: `(min-width: ${breakpoints.tablet + 1}px) and (max-width: ${breakpoints.laptop}px)`,
    });
    const isLaptop = useMediaQuery({
        query: `(min-width: ${breakpoints.laptop + 1}px) and (max-width: ${breakpoints.laptopL}px)`,
    });
    const isDesktop = useMediaQuery({ query: `(min-width: ${breakpoints.laptopL + 1}px)` });

    const isTabletAndDown = useMediaQuery({ query: `(max-width: ${breakpoints.laptop}px)` });
    const isLaptopAndDown = useMediaQuery({ query: `(max-width: ${breakpoints.laptopL}px)` });

    return {
        isMobile,
        isTablet,
        isLaptop,
        isDesktop,
        isTabletAndDown,
        isLaptopAndDown,
    };
};

export default useResponsive;
