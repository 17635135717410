const getStatus = (status: string | null, t: Function, eInvoice?: boolean): any => {
    if (status === null && eInvoice) {
        return t('pages.eInvoices.status.unknown').text;
    }
    if (!eInvoice) {
        switch (status) {
            case 'sent':
                return t('pages.invoiceCard.status.sent').text;
            case 'paid':
                return t('pages.invoiceCard.status.paid').text;
            case 'partially':
                return t('pages.invoiceCard.status.partially').text;
            case 'cancelled':
                return t('pages.invoiceCard.status.cancelled').text;
            case 'open':
                return t('pages.invoiceCard.status.open').text;
            case 'closed':
                return t('pages.invoiceCard.status.closed').text;
        }
    } else {
        switch (status) {
            case 'sending':
                return t('pages.eInvoices.status.sending').text;
            case 'sent':
                return t('pages.eInvoices.status.sent').text;
            case 'approved':
                return t('pages.eInvoices.status.verified').text;
            case 'rejected':
                return t('pages.eInvoices.status.rejected').text;
            case 'storno':
                return t('pages.eInvoices.status.storned').text;
            case 'open':
                return t('pages.eInvoices.status.open').text;
            case 'mistake':
                return t('pages.eInvoices.status.mistake').text;
            case 'draft':
                return t('pages.eInvoices.status.open').text;
            case 'cancelled':
                return t('pages.eInvoices.status.canceled').text;
        }
    }
};
export default getStatus;
