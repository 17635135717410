import { FunctionComponent, useEffect, useState } from 'react';
import styled from 'styled-components';
import useTranslations from '../../../hooks/useTranslation';
import useLanguageStore from '../../../store/language';
import communication from '../../../communication';
import ToggleSwitch from '../../../components/input/toggleSwitch';
import { NotificationsType } from '../../../types/communication/notifications';
import Loader from '../../../components/loader';
import { MQ_BREAKPOINTS } from '../../../constants/breakpoints';

const NotificationsSettings: FunctionComponent = () => {
    const { currentLang } = useLanguageStore();
    const t = useTranslations(currentLang);

    const [formData, setFormData] = useState<NotificationsType>();

    useEffect(() => {
        async function getNotifications(): Promise<void> {
            const response = await communication.getNotificationsForUser();
            const res: NotificationsType = response.data.data;
            setFormData(res);
        }

        getNotifications();
    }, []);

    useEffect(() => {
        async function updateNotifications(): Promise<void> {
            formData && (await communication.updateNotificationsForUser(formData));
        }

        updateNotifications();
    }, [formData]);
    return (
        <>
            {formData ? (
                <Content>
                    <ToggleRow>
                        <p>{t('pages.agency.notifications.healthBook').text}</p>
                        <ToggleSwitch
                            defaultChecked={formData.books}
                            onChange={() => setFormData({ ...formData, books: !formData.books })}
                        />
                    </ToggleRow>
                    <ToggleRow>
                        <p>{t('pages.agency.notifications.lawChanges').text}</p>
                        <ToggleSwitch
                            defaultChecked={formData.laws}
                            onChange={() => setFormData({ ...formData, laws: !formData.laws })}
                        />
                    </ToggleRow>
                    <ToggleRow>
                        <p>{t('pages.agency.notifications.taxChanges').text}</p>
                        <ToggleSwitch
                            defaultChecked={formData.taxes}
                            onChange={() => setFormData({ ...formData, taxes: !formData.taxes })}
                        />
                    </ToggleRow>
                    {/* <ToggleRow>
                        <p>{t('pages.agency.notifications.ecotaxChanges').text}</p>
                        <ToggleSwitch
                            defaultChecked={formData.eko_tax}
                            onChange={() => setFormData({ ...formData, eko_tax: !formData.eko_tax })}
                        />
                    </ToggleRow> */}
                    <ToggleRow>
                        <p>{t('pages.agency.notifications.featureChanges').text}</p>
                        <ToggleSwitch
                            defaultChecked={formData.new_features}
                            onChange={() => setFormData({ ...formData, new_features: !formData.new_features })}
                        />
                    </ToggleRow>
                    <ToggleRow>
                        <p>{t('pages.agency.notifications.over3million').text}</p>
                        <ToggleSwitch
                            defaultChecked={formData.limit3}
                            onChange={() => setFormData({ ...formData, limit3: !formData.limit3 })}
                        />
                    </ToggleRow>
                    <ToggleRow>
                        <p>{t('pages.agency.notifications.over5million').text}</p>
                        <ToggleSwitch
                            defaultChecked={formData.limit5}
                            onChange={() => setFormData({ ...formData, limit5: !formData.limit5 })}
                        />
                    </ToggleRow>
                    <ToggleRow>
                        <p>{t('pages.agency.notifications.over7million').text}</p>
                        <ToggleSwitch
                            defaultChecked={formData.limit7}
                            onChange={() => setFormData({ ...formData, limit7: !formData.limit7 })}
                        />
                    </ToggleRow>
                </Content>
            ) : (
                <Loader />
            )}
        </>
    );
};
export default NotificationsSettings;

const Content = styled.div`
    width: 56%;
    display: inline-block;
    vertical-align: top;
    @media screen and (max-width: ${MQ_BREAKPOINTS.tablet}) {
        width: 100%;
    }
`;

const ToggleRow = styled.div`
    margin-top: 15px;
    margin-bottom: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 20px;
    border-bottom: 1px solid var(--border-color);

    p {
        color: var(--gray);
        font-size: 15px;
        margin-right: 20px;
    }
    :hover {
        p {
            color: var(--purple);
        }
        border-bottom: 1px solid var(--purple);
    }
`;
