import styled from 'styled-components';
import { useState } from 'react';
import { Accordion } from '../../components/accordion';
import useLanguageStore from '../../store/language';
import useTranslations from '../../hooks/useTranslation';
import getInstructions from './data';
import Search from '../../components/input/search';
import { MQ_BREAKPOINTS } from '../../constants/breakpoints';

const Instructions = () => {
    const { currentLang } = useLanguageStore();
    const t = useTranslations(currentLang);
    const lang = localStorage.language;
    const defaultLang = lang === 'Srpski' ? 'rs' : lang === 'English' ? 'en' : lang === 'Русский' ? 'ru' : 'rs';

    const [searchTerm, setSearchTerm] = useState('');
    const instructions = getInstructions(t);

    const filteredInstructions = instructions.filter((instruction) =>
        instruction?.title?.toLowerCase().includes(searchTerm.toLowerCase()),
    );

    return (
        <Container>
            <Header>
                <h1>{t('navbar.pausal.instructions').text}</h1>
            </Header>
            <SubHeader>
                <label className="second-title">{`${t('pages.instructions.subtitle').text}`}</label>
            </SubHeader>
            <Filters>
                <div className="filter-bottom">
                    <div className="filter-search">
                        <Search
                            placeholder={t('pages.memos.filters.search').text}
                            onChange={(value: string) => {
                                setSearchTerm(value);
                            }}
                            noteColor={'#fff'}
                            className="select-search-filter"
                        />
                    </div>
                </div>
            </Filters>
            <InstructionsWrapper>
                <Accordion instructions={filteredInstructions} lang={defaultLang} />
            </InstructionsWrapper>
        </Container>
    );
};

export default Instructions;

const Container = styled.div`
    vertical-align: top;
    display: flex;
    flex-direction: column;
    width: 100%;

    @media screen and (max-width: ${MQ_BREAKPOINTS.tablet}) {
        width: 100%;
    }
`;

const Header = styled.div`
    position: relative;
    border-bottom: 1px solid var(--border-color);
    padding-bottom: 20px;
    display: flex;
    width: 100%;

    h1 {
        margin: 0;
    }
`;

const InstructionsWrapper = styled.div`
    margin-bottom: 20px;
`;

const SubHeader = styled.div`
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-top: 20px;
    padding: 20px;
    background-color: var(--purple);
    color: var(--white);
    text-transform: uppercase;
`;

const Filters = styled.div`
    display: flex;
    width: 100%;
    padding: 20px 0;

    .filter-bottom {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        @media (max-width: ${MQ_BREAKPOINTS.tablet}) {
            flex-direction: column;
            align-items: flex-start;
        }
    }

    .filter-search {
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: flex-end;
        @media only screen and (max-width: ${MQ_BREAKPOINTS.mobileL}) {
            display: inline-block;
        }
    }
`;
