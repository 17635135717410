import Button, { ButtonVariant } from '../../../../components/button';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { FunctionComponent, useEffect, useState } from 'react';

import { BankAccount } from '../../../../communication/bankAccounts/types';
import BankAccountNumber from './DomesticBankAccountNumber';
import ConfirmModal from '../../../../components/confirmModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Loader from '../../../../components/loader';
import Modal from '../../../../components/modal';
import { ObjectKeys } from '../../../../types/objectKeys';
import Pagination from '../../../../components/pagination';
import communication from '../../../../communication';
import { faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { isStatusSuccess } from '../../../../communication/handlers/handleRequest';
import styled from 'styled-components';
import useAllBanksQuery from '../../../../react-query/useAllBanksQuery';
import useBankAccountsQuery from '../../../../react-query/useBankAccountsQuery';
import useDomesticAccountSchema from './useDomesticBankAccountSchema';
import useLanguageStore from '../../../../store/language';
import useTranslations from '../../../../hooks/useTranslation';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { useBlocker } from 'react-router-dom';
import { DevTool } from '@hookform/devtools';
import { toast } from 'react-toastify';
import { isAxiosError } from 'axios';
import ErrorModal from '../../../../components/errorModal';
import useAgencyStore from '../../../../store/agency';
import { MQ_BREAKPOINTS } from '../../../../constants/breakpoints';

interface Props {
    agency: ObjectKeys;
}

const NewAccountSchema = z
    .object({
        bankAccountNumber1: z.string().min(1),
        bankAccountNumber2: z.string().min(1),
        bankAccountNumber3: z.string().min(1),
        bank_id: z.string().min(0),
    })
    .strict();

type NewAccountSchemaType = z.infer<typeof NewAccountSchema>;

type ParsedAccountType = BankAccount & NewAccountSchemaType;

const getParsedAccounts = (accounts: BankAccount[]): ParsedAccountType[] => {
    return accounts.map((account) => ({
        ...account,
        ...parseAccountNumber(account.account_number),
    }));
};

const parseAccountNumber = (accountNumber: string) => {
    const parts = accountNumber.split('-');
    return {
        bankAccountNumber1: parts[0] || '',
        bankAccountNumber2: parts[1] || '',
        bankAccountNumber3: parts[2] || '',
    };
};

const reparseAccountObject = (parsedAccount: ParsedAccountType): BankAccount => {
    const account_number = `${parsedAccount.bankAccountNumber1}-${parsedAccount.bankAccountNumber2}-${parsedAccount.bankAccountNumber3}`;

    function deleteBA(x: Partial<ParsedAccountType>): void {
        delete x.bankAccountNumber1;
        delete x.bankAccountNumber2;
        delete x.bankAccountNumber3;
        return;
    }

    deleteBA(parsedAccount);

    const reparsedAccount: BankAccount = {
        ...parsedAccount,
        account_number,
    };

    return reparsedAccount;
};

const DomesticAccounts: FunctionComponent<Props> = ({ agency }) => {
    const { currentLang } = useLanguageStore();
    const t = useTranslations(currentLang);
    const [loaderVisible, setLoaderVisible] = useState(false);
    const [initialData, setInitialData] = useState<BankAccount[]>([]);

    const itemsPerPage = 10;

    const [currentPage, setCurrentPage] = useState<number>(1);

    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
    const [isDeletingAccountType, setIsDeletingAccountType] = useState<'existing' | 'new' | null>(null);

    const [deletingId, setDeletingId] = useState<string | null>(null);

    const [isErrorModalOpen, setIsErrorModalOpen] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>('');

    const { DomesticAccountSchema } = useDomesticAccountSchema();

    const methods = useForm<{
        accounts: ParsedAccountType[];
        newAccounts: NewAccountSchemaType[];
    }>({
        defaultValues: {
            accounts: [],
            newAccounts: [],
        },
        resolver: zodResolver(
            z.object({
                accounts: DomesticAccountSchema,
                newAccounts: DomesticAccountSchema,
            }),
        ),
        mode: 'onChange',
    });

    const { append: appendNewAccount } = useFieldArray({
        control: methods.control,
        name: 'newAccounts',
    });

    const watchedExistingAccounts = methods.watch('accounts');
    const watchedNewAccounts = methods.watch('newAccounts');
    const isAbleToSave = Object.values(methods.formState.errors).length === 0;

    const { data, refetch, status, isFetching, invalidateBankAccounts } = useBankAccountsQuery(
        1,
        itemsPerPage,
        'domestic',
    );

    const { data: allBanks, isLoading } = useAllBanksQuery();

    const blocker = useBlocker(({ currentLocation, nextLocation }) => {
        return (
            methods.formState.isDirty &&
            (currentLocation.pathname !== nextLocation.pathname || currentLocation.search !== nextLocation.search)
        );
    });

    const addBankAccount = (): void => {
        appendNewAccount({
            bankAccountNumber1: '',
            bankAccountNumber2: '',
            bankAccountNumber3: '',
            bank_id: '',
        });
    };

    const handleDataChange = (): void => {
        setLoaderVisible(true);
        const accountsData = data?.data || [];
        if (status === 'success') {
            const accounts = getParsedAccounts(accountsData);
            setInitialData(accountsData);
            methods.setValue('accounts', [], { shouldDirty: false });
            methods.reset({ accounts: accounts, newAccounts: [] });
            setLoaderVisible(false);
        }
        setLoaderVisible(false);
    };

    const saveNewAccounts = async (): Promise<void> => {
        if (watchedNewAccounts?.length > 0 && isAbleToSave) {
            await Promise.all(
                watchedNewAccounts.map(async (account) => {
                    const newAccountData = {
                        account_number: `${account.bankAccountNumber1}-${account.bankAccountNumber2}-${account.bankAccountNumber3}`,
                        agency_id: agency.id,
                        type: 'domestic',
                        bank_id: account.bank_id,
                        swift: '',
                        iban: '',
                        currency: '',
                    };

                    try {
                        const response = await communication.createBankAccount(newAccountData);

                        if (isStatusSuccess(response.status)) {
                            communication.getAgency().then((res: ObjectKeys) => {
                                useAgencyStore.setState({
                                    agency: res.data.data,
                                });
                            });
                            toast.success(t('pages.agency.bankAccounts.messages.add_account_success').text);
                            setCurrentPage(1);
                            methods.setValue('newAccounts', []);
                        } else {
                            throw Error('Error saving domestic accounts:');
                        }
                    } catch (error) {
                        if (isAxiosError(error)) {
                            if (error.response?.data.message) {
                                setErrorMessage(error.response?.data.message);
                                setIsErrorModalOpen(true);
                            } else {
                                toast.error(t('pages.agency.bankAccounts.messages.add_account_error').text);
                            }
                        } else {
                            toast.error(t('pages.agency.bankAccounts.messages.add_account_error').text);
                        }
                    }
                }),
            );
        }
    };

    const saveExistingAccounts = async (updatedAccounts: BankAccount[]): Promise<void> => {
        if (updatedAccounts?.length > 0 && isAbleToSave) {
            await Promise.all(
                updatedAccounts.map(async (account) => {
                    try {
                        const resp = await communication.updateBankAccount(account, account.id);
                        if (!isStatusSuccess(resp.status)) {
                            throw Error('Error updating domestic accounts:');
                        }
                        toast.success(t('pages.agency.bankAccounts.messages.edit_account_success').text);
                        communication.getAgency().then((res: ObjectKeys) => {
                            useAgencyStore.setState({
                                agency: res.data.data,
                            });
                        });
                    } catch (error) {
                        if (isAxiosError(error)) {
                            if (error.response?.data.message) {
                                setErrorMessage(error.response?.data.message);
                                setIsErrorModalOpen(true);
                            } else {
                                toast.error(t('pages.agency.bankAccounts.messages.edit_account_error').text);
                            }
                        }
                    }
                }),
            );
        }
    };

    const handleSave = async (): Promise<void> => {
        setLoaderVisible(true);
        try {
            await saveNewAccounts();
            const reparsedAccountsData = watchedExistingAccounts.map((account) =>
                reparseAccountObject(account as ParsedAccountType),
            );
            const updatedAccounts = reparsedAccountsData.filter((account) => {
                const initialAccountValue = initialData.find((oldAccount) => oldAccount.id === account.id);
                return JSON.stringify(account) !== JSON.stringify(initialAccountValue);
            });
            await saveExistingAccounts(updatedAccounts);
            invalidateBankAccounts();
            await refetch();
        } catch (error) {
            console.error('Error creating or updating bank accounts:', error);
        }
        setLoaderVisible(false);
    };

    const handleDeleteExisting = async (bank_id: string): Promise<void> => {
        setLoaderVisible(true);
        try {
            const response = await communication.deleteBankAccount(bank_id);
            if (isStatusSuccess(response.status)) {
                communication.getAgency().then((res: ObjectKeys) => {
                    useAgencyStore.setState({
                        agency: res.data.data,
                    });
                });
                toast.success(t('pages.agency.bankAccounts.messages.delete_account_success').text);
                invalidateBankAccounts();
                await refetch();
            } else {
                throw Error('Error deleting domestic accounts:');
            }
        } catch (error) {
            setLoaderVisible(false);
            if (isAxiosError(error)) {
                if (error.response?.data.message) {
                    setErrorMessage(error.response?.data.message);
                    setIsErrorModalOpen(true);
                    return;
                }
            }
            toast.error(t('pages.agency.bankAccounts.messages.delete_account_error').text);
        }
        setLoaderVisible(false);
    };

    const handleDeleteNew = (): void => {
        methods.resetField('newAccounts', { keepDirty: false, defaultValue: [] });
    };

    const handleDeleteModalClose = (): void => {
        setIsDeleteModalOpen(false);
        setDeletingId(null);
    };

    const getOrderNumber = (index: number): number => {
        return (currentPage - 1) * itemsPerPage + index + 1;
    };

    useEffect(() => {
        if (!isFetching) {
            handleDataChange();
        }
    }, [data, isFetching]);

    useEffect(() => {
        if (isFetching) {
            methods.reset({ accounts: [], newAccounts: [] });
        }
    }, [currentPage]);

    useEffect(() => {
        methods.trigger();
    }, [currentLang]);

    return (
        <>
            {(loaderVisible || isFetching) && <Loader />}
            <FormWrapper>
                <FormProvider {...methods}>
                    <form onSubmit={methods.handleSubmit(handleSave)}>
                        {watchedExistingAccounts?.map((field, index) => {
                            return (
                                <BankAccountWrapper key={field.id + index}>
                                    <span className="orderNumber">{getOrderNumber(index) + '.'}</span>
                                    <BankAccountNumber
                                        index={index}
                                        allBanks={allBanks || []}
                                        isAllBanksLoading={isLoading}
                                        disabled={!!methods.formState.dirtyFields.newAccounts}
                                    />
                                    <FontAwesomeIcon
                                        icon={faTrashCan}
                                        className="deleteIcon"
                                        onClick={() => {
                                            setIsDeletingAccountType('existing');
                                            setDeletingId(field.id);
                                            setIsDeleteModalOpen(true);
                                        }}
                                    />
                                </BankAccountWrapper>
                            );
                        })}
                        {watchedNewAccounts?.map((_, index) => (
                            <BankAccountWrapper key={'newAccount-' + index}>
                                <BankAccountNumber
                                    index={index}
                                    isNew={true}
                                    allBanks={allBanks || []}
                                    isAllBanksLoading={isLoading}
                                />
                                <FontAwesomeIcon
                                    icon={faTrashCan}
                                    className="deleteIcon"
                                    onClick={() => {
                                        setIsDeletingAccountType('new');
                                        setIsDeleteModalOpen(true);
                                    }}
                                />
                            </BankAccountWrapper>
                        ))}
                        {methods.formState.isDirty && (
                            <div className="button-container">
                                <Button
                                    variant={ButtonVariant.solid}
                                    color={'var(--purple)'}
                                    size={200}
                                    className="big"
                                    disabled={!isAbleToSave}
                                >
                                    {t('pages.agency.bankAccounts.common.save').text}
                                </Button>
                            </div>
                        )}
                    </form>
                    {(watchedNewAccounts?.length === 0 || !watchedNewAccounts) && !methods.formState.isDirty && (
                        <AddServiceButton>
                            <div className="plus" onClick={addBankAccount}>
                                +
                            </div>
                            <p>{t('pages.invoices.add_account').text}</p>
                        </AddServiceButton>
                    )}
                    <DevTool control={methods.control} />
                </FormProvider>
            </FormWrapper>
            <Modal modalVisible={isDeleteModalOpen} closeModal={handleDeleteModalClose}>
                <ConfirmModal
                    close={handleDeleteModalClose}
                    message={t('pages.agency.bankAccounts.messages.delete_account_confirm').text}
                    action={async () => {
                        if (isDeletingAccountType === 'existing') {
                            await handleDeleteExisting(deletingId ?? '');
                        } else {
                            handleDeleteNew();
                        }
                        handleDeleteModalClose();
                    }}
                />
            </Modal>
            <Modal modalVisible={blocker.state === 'blocked'} closeModal={() => blocker.reset && blocker.reset()}>
                <ConfirmModal
                    message={t('warnings.youHaveUnsavedChanges').text}
                    close={() => blocker.reset && blocker.reset()}
                    action={() => blocker.proceed && blocker.proceed()}
                />
            </Modal>
            <Modal modalVisible={isErrorModalOpen} closeModal={() => setIsErrorModalOpen(false)}>
                <ErrorModal setOpenModal={setIsErrorModalOpen} t={t} errorMessage={errorMessage} />
            </Modal>
        </>
    );
};

const FormWrapper = styled.div`
    width: 100%;
    margin-top: 3rem;
    .button-container {
        display: flex;
        justify-content: center;
        align-items: center;

        @media screen and (max-width: ${MQ_BREAKPOINTS.tablet}) {
            padding-bottom: 80px;
            margin-top: 20px;
        }
    }
`;

const BankAccountWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-start;

    .orderNumber {
        margin-right: 20px;
        height: 100%;
        padding: 5px;
        text-align: left;
        color: var(--gray);
        @media screen and (max-width: ${MQ_BREAKPOINTS.tablet}) {
            margin-right: 0;
        }
    }
    .deleteIcon {
        color: var(--danger);
        cursor: pointer;
        padding: 1rem;
        @media screen and (max-width: ${MQ_BREAKPOINTS.tablet}) {
            padding: 0.5rem 0 0.5rem 1rem;
        }
    }
`;

const AddServiceButton = styled.div`
    padding: 15px 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: var(--purple);
    .plus {
        cursor: pointer;
        text-align: center;
        font-size: 25px;
        border: 2.5px solid;
        border-radius: 50%;
        width: 45px;
        height: 45px;
        padding: 0;
        padding-top: 2.5px;
        color: var(--purple);
    }
    svg {
        height: 25px;
        width: 25px;
    }
    p {
        color: var(--purple);
        font-style: italic;
        font-size: 14px;
        margin-top: 10px;
    }
`;

export default DomesticAccounts;
