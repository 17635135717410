import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { ObjectKeys } from '@/types/objectKeys';
import useLanguageStore from '../../../../store/language';
import useTranslations from '../../../../hooks/useTranslation';

interface ReportsDataTypes {
    reports?: ObjectKeys;
    filters?: ObjectKeys;
}

const ReportsMainTable: FunctionComponent<ReportsDataTypes> = ({ reports, filters }) => {
    const { currentLang } = useLanguageStore();
    const t = useTranslations(currentLang);

    const formatDate = (inputDate: string): string => {
        const date = new Date(inputDate);
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();
        const formattedDate = `${day.toString().padStart(2, '0')}.${month.toString().padStart(2, '0')}.${year}.`;
        return formattedDate;
    };

    const formatSum = (sum: number): string => {
        const zero = 0;
        return sum
            ? sum.toLocaleString('sr-SR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
            : zero.toLocaleString('sr-SR', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
    };
    return (
        <TableWrapper>
            <table className={'table'}>
                <thead>
                    <tr>
                        <th colSpan={2}>{t('pages.reports.income_generated_in_the_period').text}</th>
                        <th colSpan={5}>{t('pages.reports.total').text} (RSD)</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td colSpan={2} className={'period'}>
                            {formatDate(filters?.start_date)} - {formatDate(filters?.end_date)}
                        </td>
                        <td colSpan={5}>{formatSum(reports?.total)}</td>
                    </tr>
                </tbody>
                <thead>
                    <tr>
                        <th>{t('pages.reports.invoice_number').text}</th>
                        <th>{t('pages.reports.date').text}</th>
                        <th>{t('pages.reports.client').text}</th>
                        <th>{t('pages.reports.revenue_from_product_sales').text} (RSD)</th>
                        <th>{t('pages.reports.revenue_from_rendered_services').text} (RSD)</th>
                        <th>{t('pages.reports.total_revenue_in_currency').text}</th>
                        <th>{t('pages.reports.total_revenue_in_rsd').text}</th>
                    </tr>
                </thead>
                <tbody>
                    {reports?.invoices?.data?.map((report: ObjectKeys, index: number) => (
                        <tr key={index}>
                            <td>{report.invoice_number}</td>
                            <td>{report.invoice_date}</td>
                            <td>{report.client}</td>
                            <td>{formatSum(report.product_income)}</td>
                            <td>{formatSum(report.service_income)}</td>
                            <td>
                                {formatSum(parseFloat(report.value))} ({report.currency})
                            </td>
                            <td>{formatSum(parseFloat(report.value_in_rsd))}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </TableWrapper>
    );
};

const TableWrapper = styled.div`
    font-family: Open Sans, sans-serif;
    width: 100%;
    overflow-x: auto;
    margin-top: 20px;
    background-color: #fff;
    table {
        width: 100%;
        overflow-x: auto;
        background-color: #fff;
        border-collapse: collapse;
    }

    td,
    th {
        text-align: center;
        color: var(--gray);
        font-size: 15px;
    }

    th {
        background-color: #9b7fe9;
        color: #fff;
        border: 1px solid rgba(0, 0, 0, 0.1);
        padding: 10px;
        font-size: 15px;
        text-align: center;
        vertical-align: middle;
        font-weight: normal;
    }

    td {
        border: 1px solid rgba(0, 0, 0, 0.1);
        padding: 10px;
        font-size: 15px;
        text-align: center;
        vertical-align: middle;
    }
    .period {
        font-size: 15px;
        padding: 0px;
    }
`;

export default ReportsMainTable;
