import React, { FunctionComponent, useState, useEffect } from 'react';
import Button, { ButtonVariant } from '../../../../components/button';
import colors from '../../../../global/colors';
import styled from 'styled-components';
import { ObjectKeys } from '../../../../types/objectKeys';
import Loader from '../../../../components/loader';
import communication from '../../../../communication';
import useLanguageStore from '../../../../store/language';
import useTranslations from '../../../../hooks/useTranslation';
import useAgencyStore from '../../../../store/agency';
import moment from 'moment';
import useCharLengthValidation from '../../../../hooks/useCharLengthValidation';
import { generateQrCode } from '../../../../communication/qr';
import { handleInputNumbers } from '../../../../functions/handleInputNumbers';
import { MQ_BREAKPOINTS } from '../../../../constants/breakpoints';

interface Props {
    onClose: Function;
    paymentAccounts?: ObjectKeys;
    selectedTransfer: ObjectKeys;
    action: string;
    refresh: Function;
}

const AddOrEditModal: FunctionComponent<Props> = ({ onClose, selectedTransfer, action, refresh }) => {
    const [loader, setLoader] = useState<boolean>(false);
    const { agency } = useAgencyStore();

    const domesticBankAccounts =
        agency.bankAccounts.data.length > 0
            ? agency.bankAccounts.data
                  .filter((account: ObjectKeys) => account.type === 'domestic')
                  .map((account: ObjectKeys) => ({
                      value: account,
                  }))
            : [];

    const [qrCode, setQrCode] = useState('');
    const { currentLang } = useLanguageStore();
    const t = useTranslations(currentLang);
    const [newTransferOrder, setNewTransferOrder] = useState<ObjectKeys>({
        payment_slip_heading: '',
        orderer: `${agency?.full_name}, ${agency?.street_address} ${agency?.street_number}, ${agency?.city}`,
        purpose_of_payment: '',
        recipient: '',
        currency: 'RSD',
        total_amount: '',
        debtor_account: domesticBankAccounts[0]?.value?.account_number || '',
        payment_code: '221',
        recipient_account: '',
        call_number_debtor: '',
        model_number_debtor: '',
        call_number_recipient: '',
        model_number_recipient: '',
        year: moment().year(),
    });
    const refreshTransferOrder = (): ObjectKeys => {
        return {
            payment_slip_heading: '',
            orderer: `${agency?.full_name}, ${agency?.street_address} ${agency?.street_number}, ${agency?.city}`,
            purpose_of_payment: '',
            recipient: '',
            currency: 'RSD',
            total_amount: '',
            debtor_account: domesticBankAccounts[0]?.value?.account_number || '',
            payment_code: '221',
            recipient_account: '',
            call_number_debtor: '',
            model_number_debtor: '',
            call_number_recipient: '',
            model_number_recipient: '',
            year: moment().year(),
        };
    };

    const [agencyBankAccount, setAgencyBankAccount] = useState<ObjectKeys>({
        first: newTransferOrder?.debtor_account?.length > 0 ? newTransferOrder?.debtor_account?.split('-')[0] : '',
        second: newTransferOrder?.debtor_account?.length > 0 ? newTransferOrder?.debtor_account?.split('-')[1] : '',
        third: newTransferOrder?.debtor_account?.length > 0 ? newTransferOrder?.debtor_account?.split('-')[2] : '',
    });

    const [recipientBankAccount, setRecipientBankAccount] = useState<ObjectKeys>({
        first:
            newTransferOrder?.recipient_account?.length > 0 ? newTransferOrder?.recipient_account?.split('-')[0] : '',
        second:
            newTransferOrder?.recipient_account?.length > 0 ? newTransferOrder?.recipient_account?.split('-')[1] : '',
        third:
            newTransferOrder?.recipient_account?.length > 0 ? newTransferOrder?.recipient_account?.split('-')[2] : '',
    });

    // const modalRef = useRef<HTMLDivElement>(null);

    const scrollToTop = (): void => {
        const element = document.getElementById('scrollableModalContent');
        if (element) {
            element.parentElement?.scrollTo({
                top: 0,
                behavior: 'smooth',
            });
        }
    };

    useEffect(() => {
        if (action === 'edit') setNewTransferOrder(selectedTransfer);
    }, []);

    useEffect(() => {
        setRecipientBankAccount({
            first: newTransferOrder?.recipient_account?.split('-')[0] || '',
            second: newTransferOrder?.recipient_account?.split('-')[1] || '',
            third: newTransferOrder?.recipient_account?.split('-')[2] || '',
        });
    }, [newTransferOrder.recipient_account]);

    useEffect(() => {
        if (action !== 'edit') {
            setNewTransferOrder(refreshTransferOrder());
        }
    }, [agency]);

    useEffect(() => {
        if (selectedTransfer.id) {
            setLoader(true);

            if (action !== 'edit') {
                setNewTransferOrder(refreshTransferOrder());
            } else {
                setNewTransferOrder(selectedTransfer);
            }
            setLoader(false);
        }
    }, [selectedTransfer]);

    //validations
    const [showError, setShowError] = useState(false);
    const isPaymentSlipValid = useCharLengthValidation(
        newTransferOrder?.payment_slip_heading ? newTransferOrder?.payment_slip_heading : '',
        3,
        true,
    );
    const isOrdererValid = useCharLengthValidation(newTransferOrder?.orderer ? newTransferOrder?.orderer : '', 3);
    const isPurposeOfPaymentValid = useCharLengthValidation(
        newTransferOrder?.purpose_of_payment ? newTransferOrder?.purpose_of_payment : '',
        3,
        true,
    );
    const isRecipientValid = useCharLengthValidation(
        newTransferOrder?.recipient ? newTransferOrder?.recipient : '',
        3,
        true,
    );
    const isTotalAmountValid = useCharLengthValidation(
        newTransferOrder?.total_amount ? newTransferOrder?.total_amount : '',
        0,
        true,
    );
    const isDebtorAccountValid = useCharLengthValidation(
        newTransferOrder?.debtor_account ? newTransferOrder?.debtor_account : '',
        3,
        true,
    );
    const isRecipientAccountFirstValid = useCharLengthValidation(
        recipientBankAccount.first ? recipientBankAccount.first : '',
        3,
        true,
    );
    const isRecipientAccountSecondValid = useCharLengthValidation(
        recipientBankAccount.second ? recipientBankAccount.second : '',
        13,
        true,
    );

    const isRecipientAccountThirdValid = useCharLengthValidation(
        recipientBankAccount.third ? recipientBankAccount.third : '',
        2,
        true,
    );
    const isRecipientAccountValid =
        isRecipientAccountFirstValid === '' &&
        isRecipientAccountSecondValid === '' &&
        isRecipientAccountThirdValid === ''
            ? ''
            : 'Ovo polje je obavezno';

    useEffect(() => {
        if (
            newTransferOrder.recipient &&
            newTransferOrder.orderer &&
            newTransferOrder.total_amount &&
            newTransferOrder.purpose_of_payment &&
            newTransferOrder.currency
        ) {
            setLoader(true);
            setLoader(false);
            generateQrCode(
                newTransferOrder.recipient,
                newTransferOrder.orderer,
                newTransferOrder.total_amount.replace(/,/g, '.'),
                newTransferOrder.purpose_of_payment,
                newTransferOrder.recipient_account,
                newTransferOrder.currency,
                true,
                `${
                    newTransferOrder.model_number_recipient === '97' || newTransferOrder.model_number_recipient === '11'
                        ? newTransferOrder.model_number_recipient
                        : '00'
                }${newTransferOrder.call_number_recipient}`,
                newTransferOrder.payment_code,
            )
                .then((response: ObjectKeys | any) => {
                    if (response.i) {
                        setQrCode(response.i);
                        const updatedTransferOrder = {
                            ...newTransferOrder,
                            qr_code: `data:image/jpeg;base64,${response.i}`,
                        };
                        setNewTransferOrder(updatedTransferOrder);
                    }
                })
                .catch((error: any) => {
                    setQrCode('');
                    console.error('error', error);
                });
        }
    }, [
        newTransferOrder.recipient,
        newTransferOrder.orderer,
        newTransferOrder.total_amount,
        newTransferOrder.purpose_of_payment,
        isRecipientAccountValid,
        newTransferOrder.currency,
        // newTransferOrder.call_number_recipient,
    ]);

    const save = async (): Promise<void> => {
        if (
            isPaymentSlipValid !== '' ||
            isOrdererValid !== '' ||
            isPurposeOfPaymentValid !== '' ||
            isRecipientValid !== '' ||
            isTotalAmountValid !== '' ||
            isDebtorAccountValid !== '' ||
            isRecipientAccountValid !== ''
        ) {
            setShowError(true);
            return;
        } else {
            setShowError(false);
            setLoader(true);
            const updatedTransferOrder = {
                ...newTransferOrder,
                recipient_account: `${recipientBankAccount.first}-${recipientBankAccount.second}-${recipientBankAccount.third}`,
                debtor_account: `${agencyBankAccount.first}-${agencyBankAccount.second}-${agencyBankAccount.third}`,
                total_amount: newTransferOrder.total_amount.replace(/,/g, '.'),
            };
            if (action === 'edit') {
                const res = await communication.updateTransferOrder(updatedTransferOrder, selectedTransfer.id);
                if (res.data.data.id) setLoader(false);
            } else {
                const res = await communication.createTransferOrder(updatedTransferOrder);
                if (res.data.data.id) setLoader(false);
            }
            onClose();
            refresh();
        }
    };

    const setData = (field: string, value: string | number): void => {
        setNewTransferOrder((prevState) => ({ ...prevState, [field]: value }));
    };

    return (
        <Content id="scrollableModalContent">
            <Header>
                <h1>{t('pages.transfers.preview_title').text}</h1>
                <button
                    className={'close_button'}
                    onClick={() => {
                        onClose();
                    }}
                />
            </Header>
            {loader ? (
                <Loader />
            ) : (
                <Body>
                    <div className={'top'}>
                        <div className={'title'}>
                            <h2>{t('pages.transfers.transfer_name').text}</h2>
                            <input
                                className={'input'}
                                value={newTransferOrder?.payment_slip_heading}
                                onChange={(event) => setData('payment_slip_heading', event.target.value)}
                            />{' '}
                            <span className={'validation'}>{showError ? isPaymentSlipValid : ''}</span>
                        </div>
                    </div>
                    <div className={'left-side'}>
                        <div className={'field'}>
                            <span className={'label'}>{t('pages.transfers.orderer').text}</span>
                            <textarea
                                className={'input'}
                                placeholder={'Naziv firme'}
                                value={newTransferOrder.orderer}
                                onChange={(event) => setData('orderer', event.target.value)}
                            />
                            <span className={'validation'}>{showError ? isOrdererValid : ''}</span>
                        </div>
                        <div className={'field'}>
                            <span className={'label'}>{t('pages.transfers.purpose_of_payment').text}</span>
                            <textarea
                                className={'input'}
                                placeholder={''}
                                value={newTransferOrder?.purpose_of_payment}
                                onChange={(event) => setData('purpose_of_payment', event.target.value)}
                            />{' '}
                            <span className={'validation'}>{showError ? isPurposeOfPaymentValid : ''}</span>
                        </div>
                        <div className={'field'}>
                            <span className={'label'}>{t('pages.transfers.recipient').text}</span>
                            <textarea
                                className={'input'}
                                placeholder={''}
                                value={newTransferOrder?.recipient}
                                onChange={(event) => setData('recipient', event.target.value)}
                            />
                            <span className={'validation'}>{showError ? isRecipientValid : ''}</span>
                        </div>
                    </div>
                    <div className={'right-side'}>
                        <div className={'field m'}>
                            <span className={'label'}>{t('pages.transfers.payment_code').text}</span>
                            <input
                                className={'input'}
                                value={newTransferOrder?.payment_code}
                                maxLength={3}
                                onChange={(event) => setData('payment_code', event.target.value)}
                            />
                            <span className={'validation'}>{}</span>
                        </div>
                        <div className={'field m'}>
                            <span className={'label'}>{t('pages.transfers.currency').text}</span>
                            <input className={'input'} value={newTransferOrder.currency} />
                            <span className={'validation'}>{}</span>
                        </div>
                        <div className={'field l'}>
                            <span className={'label'}>{t('pages.transfers.amount').text}</span>
                            <input
                                className={'input'}
                                value={newTransferOrder?.total_amount}
                                onChange={(event) => {
                                    const processedValue = handleInputNumbers(event.target.value);
                                    setData('total_amount', processedValue);
                                }}
                            />
                            <span className={'validation'}>{showError ? isTotalAmountValid : ''}</span>
                        </div>
                        <div className={'field'}>
                            <span className={'label'}>{t('pages.transfers.debtor_account').text}</span>
                            <input
                                className={'input s'}
                                value={agencyBankAccount.first}
                                maxLength={3}
                                onChange={(event) =>
                                    setAgencyBankAccount((prevState) => ({ ...prevState, first: event.target.value }))
                                }
                            />
                            <input
                                className={'input xl input-left-margin '}
                                value={agencyBankAccount.second}
                                maxLength={13}
                                onChange={(event) => {
                                    setAgencyBankAccount((prevState) => ({ ...prevState, second: event.target.value }));
                                }}
                                onBlur={(event) => {
                                    setAgencyBankAccount((prevState) => ({
                                        ...prevState,
                                        second: event.target.value.padStart(13, '0'),
                                    }));
                                }}
                            />
                            <input
                                className={'input s input-left-margin '}
                                value={agencyBankAccount.third}
                                maxLength={2}
                                onChange={(event) =>
                                    setAgencyBankAccount((prevState) => ({ ...prevState, third: event.target.value }))
                                }
                            />
                            <span className={'validation'}>{showError ? isDebtorAccountValid : ''}</span>
                        </div>
                        <div className={'field'}>
                            <span className={'label'}>{t('pages.transfers.model_and_call_number_debtor').text}</span>

                            <input
                                className={'input s'}
                                value={newTransferOrder?.model_number_debtor}
                                maxLength={2}
                                onChange={(event) => setData('model_number_debtor', event.target.value)}
                            />
                            <input
                                className={'input xxl input-left-margin '}
                                value={newTransferOrder?.call_number_debtor}
                                maxLength={20}
                                onChange={(event) => setData('call_number_debtor', event.target.value)}
                            />
                        </div>
                        <div className={'field'}>
                            <span className={'label'}>{t('pages.transfers.recepient_bank_account').text}</span>

                            <input
                                className={'input s'}
                                value={recipientBankAccount?.first}
                                maxLength={3}
                                onChange={(event) =>
                                    setRecipientBankAccount((prevState) => ({
                                        ...prevState,
                                        first: event.target.value,
                                    }))
                                }
                            />
                            <input
                                className={'input xl input-left-margin '}
                                value={recipientBankAccount?.second}
                                maxLength={13}
                                onChange={(event) =>
                                    setRecipientBankAccount((prevState) => ({
                                        ...prevState,
                                        second: event.target.value,
                                    }))
                                }
                                onBlur={(event) => {
                                    setRecipientBankAccount((prevState) => ({
                                        ...prevState,
                                        second: event.target.value.padStart(13, '0'),
                                    }));
                                }}
                            />
                            <input
                                className={'input s input-left-margin '}
                                value={recipientBankAccount?.third}
                                maxLength={2}
                                onChange={(event) =>
                                    setRecipientBankAccount((prevState) => ({
                                        ...prevState,
                                        third: event.target.value,
                                    }))
                                }
                            />
                            <span className={'validation'}>{showError ? isRecipientAccountValid : ''}</span>
                        </div>
                        <div className={'field'}>
                            <span className={'label'}>{t('pages.transfers.model_and_call_number_recipient').text}</span>

                            <input
                                className={'input s'}
                                value={newTransferOrder?.model_number_recipient}
                                onChange={(event) => setData('model_number_recipient', event.target.value)}
                            />

                            <input
                                className={'input xxl input-left-margin'}
                                value={newTransferOrder?.call_number_recipient}
                                onChange={(event) => setData('call_number_recipient', event.target.value)}
                            />
                        </div>
                    </div>
                    <div className={'footer'}>
                        <div className={'signature'}>
                            <span>{t('pages.transfers.transfer_order_signature').text}</span>
                        </div>
                        <div className={'signature'}>
                            <span>{t('pages.transfers.transfer_order_date_time').text}</span>
                        </div>
                        <div className={'signature'}>
                            <span>{t('pages.transfers.transfer_execution').text}</span>
                        </div>
                    </div>
                    <div className={'qr-code'}>
                        <Button
                            variant={ButtonVariant.outlined}
                            color={colors.purple}
                            onClick={() => {
                                save();
                                if (showError) scrollToTop();
                            }}
                        >
                            {t('buttons.save').text}
                        </Button>
                        {qrCode && <img src={`data:image/png;base64,${qrCode}`} />}
                    </div>
                </Body>
            )}
        </Content>
    );
};

const Content = styled.div`
    overflow: auto;
`;

const Header = styled.div`
    position: relative;
    -ms-flex: 1 1 100%;
    flex: 1 1 100%;
    padding: 2px 15px 20px;
    h1 {
        font-size: 24px;
        text-transform: uppercase;
        color: #4c595f;
        font-weight: 400;
    }
    .close_button {
        position: absolute;
        top: 10px;
        right: 10px;
        display: block;
        background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAPoAAAD6AQAAAACgl2eQAAADbklEQVR42u2Zv24TQRDGd4mEy7TQ7LwCiJ48Dg==)
            no-repeat;
        background-size: 16px 16px;
        width: 16px;
        height: 16px;
        text-indent: -9999px;
        border: 0;
        &:hover {
            cursor: pointer;
        }
    }
`;

const Body = styled.div`
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    width: 100%;
    textarea {
        font-family: Open Sans, sans-serif;
        resize: none;
    }
    .input {
        color: #6e7883;
    }
    .top {
        width: 100%;
        .title {
            color: gray;
        }
        h2 {
            font-size: 13px;
            margin: 10px 10px;
            color: var(--gray);
        }
        input {
            display: block;
            font-size: 12px;
            font-weight: 700;
            padding: 5px 5px 5px 10px;
            border: 1px solid #adbcc1;
            line-height: 25px;
            width: -webkit-fill-available;
            margin: 10px 10px;
        }
    }

    .accounts {
        margin-bottom: 30px;
        width: 100%;
        h2 {
            font-size: 18px;
            margin: 10px 10px;
        }
        .select-accounts {
            display: flex;
            justify-content: space-between;
            width: 100%;
            .select-wrapper {
                display: flex;
                margin: 10px 10px;
                width: 49%;
            }
            .select-wrapper:last-child {
                align-self: flex-end;
            }
        }
        .bank-name {
            width: 100%;
        }
        .bank-number {
            width: 100%;
        }
    }
    .left-side {
        -ms-flex: 1 1 50%;
        flex: 1 1 50%;
        @media screen and (max-width: ${MQ_BREAKPOINTS.tablet}) {
            flex: 1 1 100%;
        }
        .input {
            display: block;
            font-size: 12px;
            font-weight: 700;
            min-height: 88px;
            padding: 5px 5px 5px 10px;
            border: 1px solid #adbcc1;
            line-height: 25px;
            width: 100%;
            color: #6e7883;
        }
    }
    .right-side {
        -ms-flex: 1 1 50%;
        flex: 1 1 50%;

        .input {
            display: inline-block;
            font-size: 12px;
            font-weight: 700;
            min-height: auto;
            padding: 5px 5px 5px 10px;
            border: 1px solid #adbcc1;
            line-height: 25px;
            width: 100%;
            color: #6e7883;
        }
    }
    .footer {
        margin: 10px 10px 20px;
        width: 100%;
        .signature {
            border: 0;
            border-top: 1px solid #000;
            width: 170px;
            margin-top: 40px;
            display: block;
            margin-right: 40px;
            float: left;
        }
    }
    .qr-code {
        display: -ms-flexbox;
        display: flex;
        flex: 1;
        -ms-flex-direction: row;
        flex-direction: row;
        -ms-flex-pack: center;
        justify-content: center;
        -ms-flex-align: center;
        align-items: center;
        img {
            width: 200px;
        }
    }
    .field {
        -ms-flex: 1 1 100%;
        flex: 1 1 100%;
        margin: 10px 10px 2px 10px;
        overflow: hidden;

        .label {
            font-size: 13px;
            color: #92a5ac;
            display: block;
            margin-bottom: 5px;
        }
    }

    .s {
        display: inline-table;
        float: left;
        width: 70px !important;
    }
    .m {
        display: inline-table;
        width: calc(29% - 10px) !important;
        margin-right: 0;
    }
    .l {
        display: inline-table;
        width: calc(38% - 10px) !important;
        margin-right: 0;
    }

    .xl {
        float: left;
        width: calc(100% - 160px) !important;
    }
    .xxl {
        float: left;
        width: calc(100% - 80px) !important;
    }

    .input-left-margin {
        margin-left: 10px;
    }

    textarea:disabled,
    input:disabled {
        background-color: white;
    }
    .validation {
        color: #ff0042;
        font-size: 12px;
        margin-top: 2px;
        margin-left: 10px;
    }
    .validation2 {
        color: #ff0042;
        font-size: 12px;
        margin-top: 2px;
        position: absolute;
        right: 300px;
    }
`;
export default AddOrEditModal;
